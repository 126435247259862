<script setup lang="ts">
const props = defineProps<{
  label: string;
  on: boolean;
}>();

const emit = defineEmits(['click']);

function handleClick() {
  emit('click');
}
</script>

<template>
  <div class="toggle gap-2 hover:cursor-pointer" @click="handleClick">
    <span>{{ props.label }}</span>
    <div
      :class="[
        props.on ? 'on bg-blue-400' : 'off',
        'switch border-gray-300 p-0.5 border border-gray-300 rounded-full',
      ]">
      <div class="ball bg-gray-500 rounded-full"></div>
    </div>
  </div>
</template>

<style scoped>
.toggle {
  display: flex;
  align-items: center;

  .switch {
    width: 40px;
    height: 22px;
    display: flex;
    margin-top: 2px;
    position: relative;

    &.off {
      .ball {
        left: 2px;
      }
    }

    &.on {
      .ball {
        background-color: white;
        left: 20px;
      }
    }

    .ball {
      position: absolute;
      height: 1rem;
      width: 1rem;
      transition: left 0.3s ease-in;
    }
  }
}
</style>
