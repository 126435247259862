import { differenceInDays, startOfToday, sub } from 'date-fns';

export function lookbackToDays(period: Lookback): number {
  const match = period.match(/(\d+)([dwmy])/);
  if (!match) throw new Error('Invalid period format');

  const [, numStr, unit] = match;
  const num = +numStr;

  const duration = {
    d: { days: num },
    w: { weeks: num },
    m: { months: num },
    y: { years: num },
  }[unit as 'd' | 'w' | 'm' | 'y'];

  const today = startOfToday();
  return differenceInDays(today, sub(today, duration));
}

export type Lookback = `${number}d` | `${number}w` | `${number}m` | `${number}y`;
