<script setup lang="ts">
const props = defineProps<{
  value: string;
}>();

const emit = defineEmits(['change']);

function onSelectDate(event: any) {
  emit('change', event.target.value);
}
</script>

<template>
  <input
    class="mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    @change="onSelectDate"
    type="date"
    :value="props.value" />
</template>
