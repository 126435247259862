<template>
  <div class="w-full">
    <ApiPageHeader title="Create a new customer" endpoint="/customers" method="POST" />

    <form class="mb-4 mt-4 pt-4 border-t border-gray-300">
      <div>
        <div
          class="space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
          <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
            <div>
              <div class="flex">
                <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                  >name <Required /></span
                ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
              </div>

              <div class="text-sm font-sm text-gray-600">The name of the customer</div>
            </div>

            <div class="mt-2 sm:col-span-2 sm:mt-0">
              <div
                class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                  v-model="name" />
              </div>
            </div>
          </div>
          <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
            <div>
              <div class="flex">
                <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                  >email <Required /></span
                ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
              </div>

              <div class="text-sm font-sm text-gray-600">The email of the customer</div>
            </div>

            <div class="mt-2 sm:col-span-2 sm:mt-0">
              <div
                class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                  v-model="email" />
              </div>
            </div>
          </div>
          <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
            <div>
              <div class="flex">
                <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                  >address</span
                ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
              </div>

              <div class="text-sm font-sm text-gray-600">The address of the customer</div>
            </div>

            <div class="mt-2 sm:col-span-2 sm:mt-0">
              <div
                class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                  v-model="address" />
              </div>
            </div>
          </div>
          <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
            <div>
              <div class="flex">
                <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                  >logo_url</span
                ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
              </div>

              <div class="text-sm font-sm text-gray-600">The url to the customer logo</div>
            </div>

            <div class="mt-2 sm:col-span-2 sm:mt-0">
              <div
                class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                  v-model="logoUrl" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex border-0 justify-end">
          <button
            type="button"
            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            @click="onSubmit">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import Required from '@/components/Required/Required.vue';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const name = ref<string>('');
const email = ref<string>('');
const address = ref<string>('');
const logoUrl = ref<string>('');
const toast = useToast();

const apiClient = new ApiClient();
const userStore = useUserStore();
const router = useRouter();

async function onSubmit() {
  await apiClient
    .createNewCustomer({
      name: name.value,
      email: email.value,
      address: address.value,
      logo_url: logoUrl.value,
      apiKey: userStore.currentApiKey,
    })
    .then(() => {
      toast.success('Customer created');
      router.push('/customers');
    })
    .catch(e => {
      toast.error(e.errorMessage);
    });
}
</script>
