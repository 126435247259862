<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useHomepageStore } from '@/stores/homepageStore';
import Heading from '@/components/Heading/Heading.vue';
import ApiActivity from '@/components/ApiActivity/ApiActivity.vue';
import { onClickOutside } from '@vueuse/core';
import Toggle from '@/components/Toggle/Toggle.vue';
import RecentEvents from '@/components/RecentEvents/RecentEvents.vue';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/solid';
import { useUserStore } from '@/stores/user';
import type { ApiRequest } from '@/types/apiRequests';
import { useRouter } from 'vue-router';
import { makeDumbPath } from '@/helpers/makeDumbPath';
import ApiRequestEntry from '@/components/ApiRequestEntry/ApiRequestEntry.vue';
import CurrentSelectedEvent from '@/components/CurrentSelectedEvent/CurrentSelectedEvent.vue';
import EventSubscriptions from '@/views/EventSubscriptions/EventSubscriptions.vue';
import ImageUpload from '@/components/ImageUpload/ImageUpload.vue';
import AccountUsers from '@/views/AccountSettings/AccountUsers.vue';
import CopyToClipboardButton from '@/components/CopyToClipboardButton/index.vue';
import ConfirmAction from '@/components/ConfirmAction/index.vue';

const router = useRouter();

const homepageStore = useHomepageStore();
const userStore = useUserStore();

const error = ref(false);

const target = ref(null);

const errorsOnly = ref(false);

onClickOutside(target, clearCurrentSelectedDay);
function clearCurrentSelectedDay() {
  if (homepageStore.currentSelectedRequest !== null) {
    homepageStore.currentSelectedRequest = null;
  } else {
    if (homepageStore.currentSelectedDay !== null) {
      homepageStore.currentSelectedDay = null;
    }
  }
  homepageStore.currentSelectedEvent = null;
}

onMounted(async () => {
  await homepageStore.getAllMeters();
});

watch(
  () => homepageStore.currentSelectedEvent,
  () => {
    if (homepageStore.currentSelectedEvent) {
      homepageStore.currentSelectedRequest = null;
      homepageStore.currentSelectedDay = null;
    }
  },
);

const selectedTab = ref('API activity');
const selectedEndpoint = ref(null);
const tabs = ['API activity', 'API Key', 'events', 'branding', 'team management'];

const isConfirmRotateKeyModalOpen = ref<boolean>(false);
const isAPIKeyVisible = ref<boolean>(false);
const isApiKeyLoading = ref<boolean>(false);

function handleToggleErrors() {
  errorsOnly.value = !errorsOnly.value;
  if (
    errorsOnly.value &&
    homepageStore.currentSelectedRequest?.status_code &&
    homepageStore.currentSelectedRequest?.status_code < 400
  ) {
    homepageStore.currentSelectedRequest = null;
  }
}

onMounted(async () => {
  await homepageStore.getStats();
  if (homepageStore.stats === null) {
    error.value = true;
  }
  if (router.currentRoute.value.query.tab) {
    selectedTab.value = router.currentRoute.value.query?.tab as string;
  }
});

function handleSelectTab(tab: string) {
  router.push({ path: '/developer-dashboard', query: { tab } });
  selectedTab.value = tab;
}

async function generateNewAPIKey() {
  userStore.rotateAccountApiKey();
}

function setAccountLogo({ image, metadata }: { image: string; metadata: any }) {
  if (image.length > 0 && metadata !== null) {
    userStore.updateAccountLogo(image, metadata);
  }
}

function updateAccountTheme() {
  userStore.updateAccountTheme(theme.value);
}

const theme = ref<string>(
  userStore.currentAccount.theme ? userStore.currentAccount.theme : '#111827',
);
const logo = ref<string>(userStore.currentAccount.logo ? userStore.currentAccount.logo : '');
const logoMetadata = ref<any>(
  userStore.currentAccount.logo_metadata ? userStore.currentAccount.logo_metadata : null,
);

onMounted(() => {
  theme.value = userStore.currentAccount.theme;
  logo.value = userStore.currentAccount.logo;
  logoMetadata.value = userStore.currentAccount.logo_metadata;
});

watch(userStore.currentAccount, () => {
  theme.value = userStore.currentAccount.theme;
  logo.value = userStore.currentAccount.logo;
  logoMetadata.value = userStore.currentAccount.logo_metadata;
});

const requests = computed(() => {
  if (homepageStore.currentSelectedDay) {
    let requests = [
      ...homepageStore.currentSelectedDay.requests.success,
      ...homepageStore.currentSelectedDay.requests.failed,
    ];
    if (errorsOnly.value) {
      requests = requests.filter(r => r.status_code >= 400);
    }
    let filtered = [];
    filtered = requests.sort(function (a, b) {
      const keyA = new Date(a.created_at),
        keyB = new Date(b.created_at);
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });

    if (selectedEndpoint.value) {
      filtered = filtered.filter(r => {
        return makeDumbPath(r.path) === selectedEndpoint.value;
      });
    }

    return filtered;
  }
  return [];
});

const successSeries = computed(() => {
  const filtered = requests.value;
  const hours = Array.from(Array(24).keys());
  return hours.map(hour => {
    const x = new Date();
    x.setSeconds(0);
    x.setMinutes(0);
    x.setHours(hour);
    return {
      x: x.toLocaleTimeString('default', { hour12: false, timeStyle: 'short' }),
      y: errorsOnly.value
        ? 0
        : filtered.filter(
            r =>
              new Date(r.created_at).getHours() === hour &&
              (r.status_code === undefined || r.status_code < 400),
          ).length,
    };
  });
});

const failedSeries = computed(() => {
  const filtered = requests.value;
  const hours = Array.from(Array(24).keys());
  return hours.map(hour => {
    const x = new Date();
    x.setSeconds(0);
    x.setMinutes(0);
    x.setHours(hour);
    return {
      x: x.toLocaleTimeString('default', { hour12: false, timeStyle: 'short' }),
      y: filtered.filter(r => new Date(r.created_at).getHours() === hour && r.status_code >= 400)
        .length,
    };
  });
});

const activityChart = computed(() => {
  return {
    options: {
      theme: {
        palette: 'palette2',
      },
      chart: {
        id: 'chart',
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
        },
        type: 'bar',
        stacked: true,
      },
      colors: ['#68d166', '#d15e5e'],
      stroke: {
        show: true,
        curve: ['smooth'],
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'number',
        labels: {
          datetimeUTC: true,
        },
      },
      yaxis: [
        {
          seriesName: 'requests',
          show: true,
          title: {
            text: 'requests',
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            formatter: function (value: any) {
              return value;
            },
          },
        },
      ],
      tooltip: {
        enabled: true,
        x: {
          show: true,
          format: 'dd MMM yyyy - H:mm',
        },
        y: {
          formatter: function (value: any) {
            return `${value} requests`;
          },
        },
      },
    },
    series: [
      {
        name: 'Successful',
        data: [...successSeries.value],
      },
      {
        name: 'Failed',
        data: [...failedSeries.value],
      },
    ],
  };
});

const loading = ref(false);

async function handleSelectRequest(request: ApiRequest) {
  loading.value = true;
  await homepageStore.getApiRequest(request.id);
  loading.value = false;
}

const selectedRequest = computed(() => {
  return homepageStore.currentSelectedRequest;
});

const endpoints = computed(() => {
  const counts: any[] = [];
  const currentDay = homepageStore.currentSelectedDay;
  if (currentDay) {
    const requests = [...currentDay.requests.success, ...currentDay.requests.failed];
    requests.forEach(r => {
      const dumbPath = makeDumbPath(r.path);
      const index = counts.findIndex(c => c.path === dumbPath);
      if (index === -1) {
        counts.push({ path: dumbPath, count: 1 });
      } else {
        counts[index].count += 1;
      }
    });
    return counts.sort((a, b) => b.count - a.count).map(m => m.path);
  }
  return [];
});
</script>

<template>
  <div v-if="error">
    <h1>Error!</h1>
  </div>
  <div v-if="!error && homepageStore.stats !== null">
    <div class="tabs flex">
      <div
        :key="tab"
        v-for="tab in tabs"
        :class="[
          'tab py-2 px-4 border-gray-300 hover:cursor-pointer hover:text-slate-500 hover:border-b-2',
          tab === selectedTab ? 'text-sky-600 font-bold bg-white border-b-2 border-sky-600' : '',
        ]"
        @click="() => handleSelectTab(tab)">
        {{ tab }}
      </div>
    </div>
    <div class="py-6">
      <div v-if="selectedTab === 'API activity'">
        <div class="flex flex-col gap-8">
          <div class="flex justify-between">
            <Heading>API Activity</Heading>
            <Toggle label="Show errors only" :on="errorsOnly" @click="handleToggleErrors" />
          </div>

          <div>
            <ApiActivity :errorsOnly="errorsOnly" />
            <div class="text-gray-500 mt-2 text-xs ml-6">
              Click on a day on the heatmap to view more information
            </div>
          </div>
          <div class="title flex justify-between align-center">
            <Heading v-if="homepageStore.currentSelectedDay"
              >API Activity @
              {{ new Date(homepageStore.currentSelectedDay?.date).toDateString() }}</Heading
            >
            <select v-if="endpoints.length > 1" v-model="selectedEndpoint">
              <option :value="null">-- Filter by endpoint --</option>
              <option v-for="endpoint in endpoints" :value="endpoint" :key="endpoint">
                {{ endpoint }}
              </option>
            </select>
          </div>
          <div v-if="requests.length === 0">
            <span class="text-lg text-gray-500">There is no API activity for this day.</span>
          </div>
          <div
            v-if="homepageStore.currentSelectedDay && requests.length > 0"
            class="chart bg-white">
            <apexchart
              class="m-4"
              :options="activityChart.options"
              :series="activityChart.series"
              height="300px"></apexchart>
          </div>
          <div v-if="requests.length > 0">
            <Heading>Requests</Heading>
            <div class="grid grid-cols-2 gap-8 mt-4">
              <div
                v-if="homepageStore.currentSelectedDay"
                class="current-day-activity flex flex-col gap-4">
                <div
                  class="requests flex flex-col gap-2 h-[600px] overflow-y-auto border border-gray-300 rounded">
                  <ApiRequestEntry
                    :key="request.id"
                    v-for="request in requests"
                    :request="request"
                    :as-header="false"
                    @click="() => handleSelectRequest(request)" />
                </div>
              </div>
              <div
                v-if="selectedRequest && requests.length > 0"
                class="selectedRequest flex flex-col gap-4 h-[600px] overflow-y-auto border border-gray-300 bg-white p-4 rounded">
                <ApiRequestEntry :request="selectedRequest" :asHeader="true" />
                <div class="flex justify-between items-center">
                  <span class="text-gray-400"
                    >Response code: {{ selectedRequest.status_code }}</span
                  >
                </div>
                <div
                  v-if="selectedRequest.request && Object.keys(selectedRequest.request).length > 0">
                  <Heading>Request</Heading>
                  <div class="bg-white border border-gray-300 shadow mt-4">
                    <highlightjs
                      class="highlight"
                      language="json"
                      :code="JSON.stringify(selectedRequest.request, null, 2)" />
                  </div>
                </div>
                <div v-if="selectedRequest.query && Object.keys(selectedRequest.query).length > 0">
                  <Heading>Query Params</Heading>
                  <div class="bg-white border border-gray-300 shadow mt-4">
                    <highlightjs
                      class="highlight"
                      language="json"
                      :code="JSON.stringify(selectedRequest.query, null, 2)" />
                  </div>
                </div>
                <div
                  v-if="
                    selectedRequest.response && Object.keys(selectedRequest.response).length > 0
                  "
                  class="flex-col flex pb-4">
                  <Heading>Response</Heading>
                  <div class="bg-white border border-gray-300 shadow mt-4">
                    <highlightjs
                      class="highlight"
                      language="json"
                      :code="JSON.stringify(selectedRequest.response, null, 2)" />
                  </div>
                </div>
              </div>
              <div
                v-else
                class="h-[600px] flex justify-center items-center border border-gray-300 rounded">
                <span class="text-gray-500 text-xl"
                  >Select a request from the list to view in more detail</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'events'" class="flex flex-col gap-8">
        <div class="grid grid-cols-2 gap-16">
          <div class="flex gap-4 flex-col">
            <Heading>Recent Events (last 500)</Heading>
            <RecentEvents />
          </div>
          <div class="mt-[85px]">
            <CurrentSelectedEvent />
          </div>
        </div>
        <hr />
        <EventSubscriptions />
      </div>
      <div v-if="selectedTab === 'API Key'">
        <div>
          <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12 sm:w-full">
            <div class="sm:col-span-6">
              <Heading>API Key</Heading>
              <p class="mt-4 max-w-2xl text-sm text-gray-500">
                Openvolt has an API key that allows you work directly with the API. More details on
                the API reference can be found at
                <a href="https://docs.openvolt.com" target="new">https://docs.openvolt.com</a>
              </p>
            </div>
          </div>
        </div>
        <div class="flex border-t sm:border-gray-200 mt-4">
          <div class="mt-6 grid grid-cols-12 gap-y-6 gap-x-4 sm:grid-cols-12 sm:w-full">
            <div class="flex sm:col-span-6 col-span-12">
              <span
                class="flex grow-0 shrink items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                api_key
              </span>
              <input
                :type="isAPIKeyVisible ? 'text' : 'password'"
                :value="userStore.currentAccount.liveApiKey"
                class="grow shrink-0 focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
            </div>
            <div
              v-if="userStore.currentAccount.liveApiKey && !isApiKeyLoading"
              class="col-span-5 flex">
              <ConfirmAction
                v-if="isConfirmRotateKeyModalOpen"
                title="Rotate API Key"
                message="Warning! This will invalidate the current key and generate a new one, this action CANNOT BE REVERSED. Are you sure you want to proceed?"
                confirmButtonText="Yes, rotate the key"
                @confirm="generateNewAPIKey()"
                @close="isConfirmRotateKeyModalOpen = false">
              </ConfirmAction>
              <button
                type="button"
                @click="isAPIKeyVisible = !isAPIKeyVisible"
                class="items-center mr-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                <EyeIcon v-if="!isAPIKeyVisible" class="h-5 w-5" aria-hidden="true" />
                <EyeSlashIcon v-if="isAPIKeyVisible" class="h-5 w-5" aria-hidden="true" />
              </button>

              <button
                type="button"
                @click="isConfirmRotateKeyModalOpen = true"
                class="items-center mr-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-red-600 hover:bg-red-800 text-white hover:bg-gray-50">
                Rotate Key
              </button>

              <CopyToClipboardButton
                :toCopy="userStore.currentAccount.liveApiKey"
                name="Live API Key" />
            </div>
            <div v-else>
              <button
                type="button"
                class="items-center mr-2 px-7 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white bg-green-50">
                Updated
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'branding'">
        <div class="grid grid-cols-1 gap-y-6 gap-x-4">
          <div>
            <Heading>Branding</Heading>

            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Add your own look and feel to important customer flows such as hosted consent.
            </p>
          </div>
        </div>
        <div class="border-t sm:border-gray-200 mt-4 pt-4">
          <p class="pr-3 leading-6 font-medium text-gray-900">Theme</p>
          <p class="text-sm text-gray-500">
            Your theme colour is used in some areas of customer-facing products such as hosted
            consent to help users recognise your business.
          </p>
          <div class="mt-6 flex gap-y-6 gap-x-4 sm:grid-cols-12 sm:w-full items-center">
            <div class="flex grow gap-4">
              <input
                type="color"
                id="theme"
                name="theme"
                v-model="theme"
                @change="updateAccountTheme" />
              <label v-if="theme != null" for="theme">{{ theme }}</label>
            </div>
          </div>
        </div>
        <div class="border-t sm:border-gray-200 mt-4 pt-4">
          <p class="pr-3 leading-6 font-medium text-gray-900">Logo</p>
          <p class="text-sm text-gray-500">
            We would advise using a square logo such as an app icon to ensure the greatest amount of
            compatability with customer devices.
          </p>
          <div class="mt-6 flex gap-y-6 gap-x-4 sm:grid-cols-12 sm:w-full items-start">
            <div class="flex grow gap-4">
              <ImageUpload @change="setAccountLogo" :image="logo" :metadata="logoMetadata" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'team management'">
        <AccountUsers />
      </div>
    </div>
  </div>
</template>

<style scoped>
.contextual-slide-in {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 600px;
  overflow-y: auto;
  transition:
    transform 0.1s linear,
    opacity 0.1s linear;

  &.closed {
    transform: translateX(100%);
    opacity: 0;
  }
  &.open {
    transform: translateX(0);
    opacity: 1;
  }
}

.tabs {
  .tab {
    text-transform: capitalize;
    &:first-child {
      border-radius: 5px 0 0 0;
    }
    &:last-child {
      border-radius: 0 5px 0 0;
    }
    &:nth-child(n + 2) {
      border-left: 0;
    }
  }
}
</style>
