<template>
  <div class="w-full">
    <ApiPageHeader
      title="List all Inverters"
      endpoint="/inverters"
      method="GET"
      button-link="/inverters/new"
      button-link-text="Add Inverter" />
    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="mt-4 py-4 border-t border-b border-gray-200 w-full flex bg-gray-50 gap-4 px-4 items-center">
        <h2 class="text-sm text-gray-900">QUERY PARAMS</h2>
        <ChevronDownIcon v-if="!open" class="w-5" />
        <ChevronUpIcon v-if="open" class="w-5" />
      </DisclosureButton>
      <DisclosurePanel class="text-gray-500">
        <form class="mt-4">
          <div
            class="space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >page</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >integer</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">The page number to retrieve</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div
                  class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                    v-model="currentPage" />
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >page_size</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >integer</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">
                  The maximum number of inverters to retrieve per page
                </div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div
                  class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                    v-model="pageSize" />
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >inverter_number</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >integer</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">filter by inverter_number</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div
                  class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                    v-model="inverterNumber" />
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >inverter_id</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >integer</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">Filter by inverter_id</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div
                  class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                    v-model="inverterId" />
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >customer_id</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >integer</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">Filter by customer_id</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div
                  class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                    v-model="customerId" />
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >customer_name</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >string</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">Filter by customer_name</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div
                  class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                    v-model="customerName" />
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >consent_id</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >string</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">Filter by consent_id</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div
                  class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                    v-model="consentId" />
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >sort_by</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >string</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">Sort results by</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="flex">
                  <div>
                    <select
                      v-model="sortBy"
                      class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                      <option>last_data_pull_date</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >sort_order</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >string</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">Sort ascending or descending</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="flex">
                  <div>
                    <select
                      v-model="sortOrder"
                      class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                      <option>asc</option>
                      <option>desc</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >missing_consent</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >string</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">Flag for missing consent</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="flex">
                  <div>
                    <select
                      v-model="missingConsent"
                      class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                      <option></option>
                      <option>true</option>
                      <option>false</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >expired_consent</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >string</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">Flag for expired consent</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="flex">
                  <div>
                    <select
                      v-model="expiredConsent"
                      class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                      <option></option>
                      <option>true</option>
                      <option>false</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >is_disabled_by_user</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >string</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">Flag for missing consent</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="flex">
                  <div>
                    <select
                      v-model="isDisabledByUser"
                      class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                      <option></option>
                      <option>true</option>
                      <option>false</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
              <div>
                <div class="flex">
                  <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                    >has_invalid_inverter_number</span
                  ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                    >string</span
                  >
                </div>

                <div class="text-sm font-sm text-gray-600">Flag for missing consent</div>
              </div>

              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="flex">
                  <div>
                    <select
                      v-model="hasInvalidInverterNumber"
                      class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                      <option></option>
                      <option>true</option>
                      <option>false</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex border-0 justify-end">
            <button
              type="button"
              class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              @click="onSubmit">
              Submit
            </button>
          </div>
        </form>
      </DisclosurePanel>
    </Disclosure>

    <div class="mt-4 sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">Inverters</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all your inverters filtered by the above query params
        </p>
      </div>
    </div>
    <table class="divide-y w-full divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
            Inverter
          </th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Customer
          </th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Address
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr v-if="inverters.length === 0">
          <td colspan="3" class="py-8 text-center text-gray-500">
            You don't have any inverters added, yet.
          </td>
        </tr>
        <tr v-for="inverter in inverters" :key="inverter.inverterNumber">
          <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
            <div class="flex items-center">
              <div>
                <BoltIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-4 hover:cursor-pointer" @click="showDetails(inverter.id)">
                <div class="font-medium text-gray-900">
                  {{ inverter.inverterNumber }}
                </div>
                <div class="mt-1 text-gray-500">
                  {{ inverter.dataSource }}
                </div>
              </div>
            </div>
          </td>
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            {{ inverter.customerName }}
          </td>
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            <div class="text-gray-900">{{ inverter.address }}</div>
            <div class="mt-1 text-gray-500">
              {{ inverter.description }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { BoltIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid';
import type { Inverter } from '@/types/inverter';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';
import type { GetInvertersRequest } from '@/types/inverter';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

const apiClient = new ApiClient();
const userStore = useUserStore();
const router = useRouter();

const currentPage = ref<number>(1);
const pageSize = ref<number>(300);
const inverterNumber = ref<string>('');
const inverterId = ref<string>('');
const customerId = ref<string>('');
const consentId = ref<string>('');
const customerName = ref<string>('');
const sortBy = ref<string>('last_data_pull_date');
const sortOrder = ref<string>('desc');
const missingConsent = ref<string>('');
const expiredConsent = ref<string>('');
const hasInvalidInverterNumber = ref<string>('');
const isDisabledByUser = ref<string>('');

const inverters = ref<Inverter[]>([]);
const resultsNumber = ref<number>(0);

function showDetails(inverterId: string) {
  router.push('/inverters/' + inverterId);
}

async function getInverters() {
  const requestObject: GetInvertersRequest = {
    currentPage: currentPage.value,
    pageSize: pageSize.value,
    inverterNumber: inverterNumber.value,
    inverterId: inverterId.value,
    customerId: customerId.value,
    customerName: customerName.value,
    consentId: consentId.value,
    // commented until api works
    // sortBy: sortBy.value,
    // sortOrder: sortOrder.value,
    apiKey: userStore.currentApiKey,
    missingConsent: missingConsent.value,
    expiredConsent: expiredConsent.value,
    hasInvalidInverterNumber: hasInvalidInverterNumber.value,
    isDisabledByUser: isDisabledByUser.value,
  };

  return await apiClient.getInverters(requestObject).catch(() => {
    return { inverters: [], totalCount: 0 };
  });
}

async function handleInvertersFetch() {
  const invertersData = await getInverters();
  inverters.value = invertersData.inverters;
  resultsNumber.value = invertersData.totalCount;
}

async function onSubmit() {
  handleInvertersFetch();
}

onMounted(async () => {
  handleInvertersFetch();
});

watch(
  () => userStore.currentApiKey,
  async () => {
    await handleInvertersFetch();
  },
);
</script>
