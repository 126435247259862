<script setup lang="ts">
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import { ApiClient } from '@/helpers/apiClient';
import { useSubscriptionStore } from '@/stores/subscriptionStore';
import { useUserStore } from '@/stores/user';
import { onMounted, watch } from 'vue';
import SubscriptionsTable from '@/components/SubscriptionsTable/SubscriptionsTable.vue';
import { useNotificationAccountStore } from '@/stores/notificationAccountStore';
import { SubscriptionType } from '@/types/subscriptions/subscription.types';

const api = new ApiClient();
const notificationAccountStore = useNotificationAccountStore();
const subscriptionStore = useSubscriptionStore();
const userStore = useUserStore();

async function refreshSubscriptions() {
  const webhooks = await api.listWebhookSubscriptions(userStore.currentApiKey);
  if (webhooks) subscriptionStore.webhookSubscriptions = webhooks;
  const emails = await api.listEmailSubscriptions(userStore.currentApiKey);
  if (emails) subscriptionStore.emailSubscriptions = emails;
}

onMounted(async () => {
  await notificationAccountStore.getNotificationAccount();
  if (!notificationAccountStore.notificationAccount)
    await notificationAccountStore.createNotificationAccount();
  await refreshSubscriptions();
});

watch(
  () => userStore.currentApiKey,
  async () => await refreshSubscriptions(),
);
</script>
<template>
  <div class="flex flex-col gap-8">
    <div>
      <ApiPageHeader
        title="Webhook Subscriptions"
        endpoint="/webhook-subscriptions"
        method="get"
        button-link="/event-subscriptions/new"
        button-link-text="Subscribe to an event" />

      <SubscriptionsTable
        :subscriptions="subscriptionStore.webhookSubscriptions"
        :type="SubscriptionType.WEBHOOK" />
    </div>
    <hr />
    <div>
      <ApiPageHeader title="Email Subscriptions" endpoint="/email-subscriptions" method="get" />

      <SubscriptionsTable
        :subscriptions="subscriptionStore.emailSubscriptions"
        :type="SubscriptionType.EMAIL" />
    </div>
  </div>
</template>
