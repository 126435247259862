<template>
  <div class="z-10 pr-4 sidebar sticky top-4">
    <div class="flex flex-col w-64 h-full">
      <div class="flex-shrink-0 flex items-center justify-start px-4 h-auto py-3 relative">
        <router-link to="/">
          <img class="w-14" :src="logoUrl" alt="openvolt" />
        </router-link>
      </div>

      <div class="flex-1 flex flex-col">
        <nav class="flex-1 px-2 py-0 space-y-1">
          <router-link
            v-for="item in navigationItems"
            :key="item.name"
            :to="item.href"
            @click="setActive(item.name)"
            :class="[
              item.isActive
                ? 'bg-white text-gray-900 border border-gray-200 shadow'
                : 'text-gray-900 hover:bg-white hover:shadow',
              'group flex items-center px-2 py-2 ml-0 mt-1 mb-1 mr-2 text-sm font-sm rounded-md border border-slate-100',
            ]">
            <component
              :is="item.icon"
              :class="[
                item.isActive ? 'text-gray-600' : 'text-gray-400 group-hover:text-gray-600',
                'mr-3 h-6 w-6',
              ]"
              aria-hidden="true" />
            {{ item.name }}
          </router-link>
        </nav>
      </div>

      <Menu as="div" class="mb-4 relative inline-block text-left w-full">
        <MenuButton
          class="bg-white inline-flex w-full justify-between rounded-md border border-gray-300 shadow-sm px-4 py-2 text-medium font-sm text-gray-900 bg-gradient-to-r from-white to-slate-100">
          <div class="flex gap-4">
            <BuildingOfficeIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
            <span>
              {{ userStore.currentAccount.name }}
            </span>
          </div>

          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <MenuItems
          class="absolute w-full overflow-y-auto max-h-40 top-full rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <MenuItem
            v-for="account in userStore.accountsList"
            :key="account.name"
            v-on:click="changeSessionAccount(account)"
            v-slot="{ active }">
            <a
              href="#"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm',
              ]">
              <BuildingOfficeIcon
                class="mr-3 h-5 w-5 text-gray-900 group-hover:text-gray-500"
                aria-hidden="true" />
              {{ account.name }}
            </a>
          </MenuItem>
        </MenuItems>
      </Menu>

      <a
        target="_blank"
        href="https://docs.openvolt.com/reference/introduction"
        class="w-full text-gray-900 flex flex-col gap-2 border border-gray-300 shadow-sm box-border items-center p-4 text-sm font-medium rounded-md bg-gradient-to-r from-white to-slate-100 mb-4">
        <div class="flex justify-between w-full">
          <span class="font-bold">Reference API</span>
          <BookOpenIcon class="w-6" />
        </div>
        <span>Having trouble? Please contact us for more questions.</span>
      </a>

      <div
        class="justify-self-end w-0 sm:w-max sm:shrink-0 mt-2 mb-2 mr-2 invisible sm:visible"
        v-if="isAuthenticated">
        <div class="flex items-center justify-between gap-4">
          <img
            referrerpolicy="no-referrer"
            v-if="user?.picture"
            class="inline-block h-8 w-8 border border-gray-300 bg-white rounded-full"
            :src="user?.picture"
            alt="" />
          <div>
            <p class="text-sm font-medium">
              {{ user?.name }}
            </p>
            <div @click="auth0Logout">
              <p class="cursor-pointer text-xs font-medium text-gray-400 group-hover:text-gray-200">
                Logout
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.sidebar {
  height: calc(100dvh - 2rem);

  &.isTest {
    height: calc(100dvh - 3.5rem);
  }
}
</style>
<script lang="ts" setup>
import { ChevronDownIcon, BuildingOfficeIcon } from '@heroicons/vue/24/solid';
import { BookOpenIcon } from '@heroicons/vue/24/outline';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import logoUrl from '@/assets/images/openvolt-icon.svg';
import { useUserStore } from '@/stores/user';
import type { Account } from '@/types/user';
import { useNavigation } from '@/composables/useNavigation';
import { useAuth0 } from '@auth0/auth0-vue';

const { isAuthenticated, user, logout } = useAuth0();

const userStore = useUserStore();

const { navigationItems, setActive } = useNavigation();

function changeSessionAccount(account: Account) {
  if (account.id === userStore.currentAccount.id) {
    return;
  }
  userStore.handleAccountChange(account);
}

function auth0Logout() {
  logout({ logoutParams: { returnTo: window.location.origin } });
  userStore.handleLogout();
}
</script>
