export interface SubscriptionModel {
  description?: string | null;
  events: string[];
}

export interface CreateEmailSubscriptionRequest extends SubscriptionModel {
  email: string;
  subject?: string | null;
}

export interface CreateWebhookSubscriptionRequest extends SubscriptionModel {
  url: string;
}

export interface UpdateEmailSubscriptionRequest extends Partial<CreateEmailSubscriptionRequest> {}

export interface UpdateWebhookSubscriptionRequest
  extends Partial<CreateWebhookSubscriptionRequest> {}

export interface EmailSubscriptionResponse extends CreateEmailSubscriptionRequest {
  id: string;
  deleted: boolean;
}

export interface WebhookSubscriptionResponse extends CreateWebhookSubscriptionRequest {
  id: string;
  deleted: boolean;
}

export enum SubscriptionType {
  EMAIL = 'email',
  WEBHOOK = 'webhook',
}
