<template>
  <div class="w-full">
    <div class="mb-20">
      <ApiPageHeader
        title="Retrieve a customer"
        :endpoint="`/customers/${customer.id}`"
        method="GET" />
      <div class="mb-20 mt-4 pt-4 border-t border-gray-300" v-if="loadingError">
        {{ loadingError }}
      </div>
      <div v-else>
        <div>
          <div class="flex my-4 rounded-lg border-t border-gray-300 pt-4">
            <div
              class="bg-slate-100 border border-gray-300 rounded p-5 flex flex-col justify-between">
              <div>
                <div class="text-sm font-large text-gray-900">Customer Name</div>
                <div class="text-xl font-small text-gray-900">
                  {{ customer.name }}
                </div>
              </div>
              <div>
                <img
                  v-if="customer.logoUrl && customer.logoUrl.length > 0"
                  class="object-cover w-full h-full rounded-lg pt-5"
                  :src="customer.logoUrl"
                  alt="customer image" />
              </div>
            </div>
            <div class="p-5">
              <div>
                <div class="px-4 sm:px-0">
                  <h3 class="text-base font-semibold leading-7 text-gray-900">Customer Details</h3>
                  <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                    Detailed information about the Customer
                  </p>
                </div>
                <div class="mt-6 border-t border-gray-100">
                  <div>
                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Customer Id</dt>
                      <dd
                        class="flex items-center mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {{ customer.id }}
                        <CopyToClipboardButton :toCopy="customer.id" name="Customer ID" />
                      </dd>
                    </div>
                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Customer</dt>
                      <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {{ customer.name }}
                      </dd>
                    </div>
                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Address</dt>
                      <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {{ customer.address }}
                      </dd>
                    </div>
                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Contact</dt>
                      <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {{ customer.email }}
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Disclosure v-slot="{ open }">
          <DisclosureButton
            class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
            <div>
              <ApiPageHeader
                title="Update a customer"
                :endpoint="`/customers/${customer.id}`"
                method="PUT" />
            </div>
            <ChevronDownIcon v-if="!open" class="w-5" />
            <ChevronUpIcon v-if="open" class="w-5" />
          </DisclosureButton>
          <DisclosurePanel class="text-gray-500">
            <div
              class="mt-4 space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <div>
                  <div class="flex">
                    <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >name</span
                    ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >string</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">The name of the customer</div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="name" />
                  </div>
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <div>
                  <div class="flex">
                    <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >email</span
                    ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >string</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">The email of the customer</div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="email" />
                  </div>
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <div>
                  <div class="flex">
                    <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >address</span
                    ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >string</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">The address of the customer</div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="address" />
                  </div>
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <div>
                  <div class="flex">
                    <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >logo_url</span
                    ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >string</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">The url to the customer logo</div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="logoUrl" />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button
                class="w-32 h-9 text-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none disabled:opacity-50"
                @click="updateCustomer">
                Update Customer
              </button>
            </div>
          </DisclosurePanel>
        </Disclosure>
        <div class="my-8"></div>
        <div
          class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
          <div>
            <ApiPageHeader
              title="Delete customer"
              :endpoint="`/customers/${customer.id}`"
              method="DELETE" />
          </div>
          <div>
            <button
              class="w-32 h-9 text-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none disabled:opacity-50"
              @click="openDeleteConfirmation">
              Delete Customer
            </button>
            <ConfirmAction
              v-if="isConfirmModalOpen"
              title="Delete Customer"
              message="Are you sure you want to Delete this Customer? This action cannot be undone!"
              confirmButtonText="Delete Customer"
              @confirm="deleteCustomer"
              @close="closeDeleteConfirmation" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import type { Customer } from '@/types/customer';
import { DisclosureButton, Disclosure, DisclosurePanel } from '@headlessui/vue';
import { useRoute } from 'vue-router';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import ConfirmAction from '@/components/ConfirmAction/index.vue';
import CopyToClipboardButton from '@/components/CopyToClipboardButton/index.vue';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid';

const router = useRouter();
const route = useRoute();
const apiClient = new ApiClient();
const userStore = useUserStore();
const customer = ref<Customer>({
  id: '',
  name: '',
  email: '',
  accountId: '',
  address: '',
  logoUrl: '',
  notes: [],
});
const name = ref<string>('');
const email = ref<string>('');
const address = ref<string>('');
const logoUrl = ref<string>('');
const loadingError = ref<string | null>(null);

const isConfirmModalOpen = ref<boolean>(false);
const toast = useToast();

function openDeleteConfirmation() {
  isConfirmModalOpen.value = true;
}

function closeDeleteConfirmation() {
  isConfirmModalOpen.value = false;
}

async function deleteCustomer() {
  await apiClient
    .deleteCustomer({
      id: route.params.id as string,
      apiKey: userStore.currentApiKey,
    })
    .then(() => {
      toast.success('Customer deleted');
      router.push('/customers');
    })
    .catch(e => {
      toast.error(e.errorMessage);
    });
}

async function updateCustomer() {
  await apiClient
    .updateCustomer({
      id: route.params.id as string,
      name: name.value,
      email: email.value,
      address: address.value,
      logo_url: logoUrl.value,
      apiKey: userStore.currentApiKey,
    })
    .then(async () => {
      toast.success('Customer updated');
      await getCutomerDetails();
      name.value = '';
      email.value = '';
      address.value = '';
      logoUrl.value = '';
    })
    .catch(e => {
      toast.error(e.errorMessage);
    });
}

async function getCutomerDetails() {
  await apiClient
    .getCustomer({
      id: route.params.id as string,
      apiKey: userStore.currentApiKey,
    })
    .then(res => {
      customer.value = res;
    })
    .catch(e => {
      loadingError.value = e.errorMessage;
    });
}

onMounted(async () => {
  await getCutomerDetails();
});

watch(
  () => userStore.currentApiKey,
  async () => {
    router.replace('/customers');
  },
);
</script>
