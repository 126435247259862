<script setup lang="ts"></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://web.resource.org/cc/"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    version="1.1"
    baseProfile="full"
    width="160px"
    height="160px"
    viewBox="0 0 160 160"
    preserveAspectRatio="xMidYMid meet"
    id="svg_document"
    style="zoom: 1"
    visibility="visible">
    <!-- Created with macSVG - https://macsvg.org/ - https://github.com/dsward2/macsvg/ -->
    <title id="svg_document_title" visibility="visible">corethings.svg</title>
    <defs id="svg_document_defs" visibility="visible"></defs>
    <g id="main_group" visibility="visible"></g>
    <circle id="core" cx="80px" cy="80px" r="42px" fill="lightgrey" visibility="visible"></circle>
    <circle
      id="trajectory"
      cx="80px"
      cy="80px"
      r="60px"
      fill="none"
      stroke="lightgrey"
      stroke-width="2px"
      visibility="visible"></circle>
    <circle
      id="thing_loading"
      cx="80px"
      cy="20px"
      r="6px"
      fill="lightgrey"
      stroke="none"
      visibility="visible"></circle>
    <circle
      id="thing_constant"
      cx="80px"
      cy="20px"
      r="6px"
      fill="lightgrey"
      stroke="none"
      visibility="visible"></circle>
  </svg>
</template>

<style scoped></style>
