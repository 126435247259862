<template>
  <button
    class="flex ml-4 items-center py-1 px-2 rounded text-gray-100 button-copy"
    @click="copyToClipboard">
    <ClipboardDocumentListIcon class="w-5 h-5" />
  </button>
</template>

<script setup lang="ts">
import { ClipboardDocumentListIcon } from '@heroicons/vue/24/outline';
import copyTextToClipboard from '@/helpers/copyToClipboard';
import { useToast } from 'vue-toastification';
import type { ToastID } from 'vue-toastification/dist/types/types';

interface Props {
  toCopy: string;
  name: string;
}

const props = defineProps<Props>();
const toast = useToast();
let activeToast: ToastID | null = null;

function copyToClipboard() {
  copyTextToClipboard(props.toCopy);
  if (activeToast) {
    toast.dismiss(activeToast);
  }
  activeToast = toast(`${props.name} copied to clipboard`, { timeout: 2000 });
}
</script>

<style scoped>
.button-copy {
  background-color: var(--primary-color);
}
</style>
