<script setup lang="ts">
import type { TriggerEventResponse } from '@/types/events/event.types';
import { useTimeAgo } from '@vueuse/core';
import { computed } from 'vue';

const props = defineProps<{
  event: TriggerEventResponse;
}>();

const timeAgo = computed(() => {
  return useTimeAgo(new Date(props.event.created_at)).value;
});
</script>

<template>
  <div class="grid grid-cols-4 gap-4 items-center">
    <div class="col-span-3">
      <div>{{ props.event.event_id }}</div>
      <div class="text-xs text-gray-500">
        {{
          new Date(event.created_at).toLocaleString('default', {
            timeStyle: 'short',
            dateStyle: 'full',
          })
        }}
      </div>
    </div>
    <div class="text-gray-500 text-right">{{ timeAgo }}</div>
  </div>
</template>

<style scoped></style>
