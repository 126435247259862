<template>
  <div class="w-full">
    <ApiPageHeader
      title="List all Customers"
      subtitle="View and manage customers"
      endpoint="/customers"
      button-link="/customers/new"
      button-link-text="Add Customer"
      method="get" />
    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="mt-4 py-4 border-t border-b border-gray-200 w-full flex bg-gray-50 gap-4 px-4 items-center">
        <h2 class="text-sm text-gray-900">QUERY PARAMS</h2>
        <ChevronDownIcon v-if="!open" class="w-5" />
        <ChevronUpIcon v-if="open" class="w-5" />
      </DisclosureButton>
      <DisclosurePanel class="text-gray-500">
        <form class="my-4">
          <div>
            <div
              class="space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <div>
                  <div class="flex">
                    <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >page</span
                    ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >integer</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">The page number to retrieve</div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="currentPage" />
                  </div>
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <div>
                  <div class="flex">
                    <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >page_size</span
                    ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >integer</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">
                    The maximum number of customers to retrieve per page
                  </div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="pageSize" />
                  </div>
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <div>
                  <div class="flex">
                    <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >email</span
                    ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >string</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">Filter results by email</div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="emailFilter" />
                  </div>
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <div>
                  <div class="flex">
                    <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >customer_id</span
                    ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >string</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">Filter results by customer_id</div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="customerId" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex border-0 justify-end">
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                @click="onSubmit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </DisclosurePanel>
    </Disclosure>

    <div class="mt-4">
      <ul role="list" class="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-6">
        <li
          @click="showDetails(customer.id)"
          v-for="customer in customers"
          :key="customer.id"
          class="flex flex-col border border-gray-200 rounded-md shadow p-4 items-start justify-between hover:cursor-pointer hover:border-blue-700">
          <img
            v-if="customer.logoUrl && customer.logoUrl.length > 0"
            class="h-auto w-full mb-32 2xl:w-40"
            :src="customer.logoUrl"
            alt="" />
          <UserIcon v-else class="w-full mb-32 text-gray-400 2xl:w-40" />
          <div class="flex flex-1 flex-col flex-grow-0 w-full">
            <h2 class="text-lg font-semibold">
              {{ customer.name }}
            </h2>

            <span class="text-sm text-gray-500 break-words">
              {{ customer.email }}
            </span>
            <span class="text-sm text-gray-500 break-words">
              {{ customer.address }}
            </span>
          </div>
        </li>
      </ul>
    </div>

    <!-- <div class="w-full grid grid-cols-2">
                <div class="justify-start m-4 text-sm text-gray-600">
                    Viewing {{ 1 + (page - 1) * page_size }} - {{ page * page_size }} of
                    {{ total_count }} results
                </div>
                <div class="flex justify-end mr-5">
                    <button
                        type="button"
                        v-if="has_less"
                        class="m-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        @click="getPrevious"
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        v-if="has_more"
                        class="m-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        @click="getNext"
                    >
                        Next
                    </button>
                </div>
            </div> -->

    <!-- <aside
      class="mt-28 m-5 w-1/3 bg-white hidden xl:block border rounded-lg font-mono text-xs bg-gray-50"
      v-if="developer_tools"
    >
      <div v-for="response in responses" :key="response">
        <div class="bg-gray-200 text-sm p-2">Request</div>
        <div class="ml-2 mt-2 mb-2 whitespace-pre overflow-x-auto">
          {{ JSON.stringify(response.config, null, 2) }}
        </div>
        <div class="bg-gray-200 text-sm p-2">Response</div>
        <div class="ml-2 mt-2 mb-2 whitespace-pre overflow-x-auto">
          {{ JSON.stringify(response.data, null, 2) }}
        </div>
        <hr />
      </div>
    </aside> -->
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid';
import { UserIcon } from '@heroicons/vue/24/outline';
import { ApiClient } from '@/helpers/apiClient';
import type { Customer } from '@/types/customer';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';

const currentPage = ref<number>(1);
const pageSize = ref<number>(300);
const emailFilter = ref<string>('');
const customerId = ref<string>('');
const customers = ref<Customer[]>([]);

const apiClient = new ApiClient();
const userStore = useUserStore();
const router = useRouter();

async function onSubmit() {
  customers.value = await getCustomers();
}

async function getCustomers() {
  const result = await apiClient
    .getCustomers({
      currentPage: currentPage.value,
      pageSize: pageSize.value,
      email: emailFilter.value,
      customerId: customerId.value,
      apiKey: userStore.currentApiKey,
    })
    .catch(() => {
      return { customers: [], hasMore: false };
    });
  return result.customers;
}

function showDetails(customerId: string) {
  router.push('/customers/' + customerId);
}

onMounted(async () => {
  customers.value = await getCustomers();
});

watch(
  () => userStore.currentApiKey,
  async () => {
    customers.value = await getCustomers();
  },
);
</script>
