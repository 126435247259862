<script setup lang="ts">
import { ConsentMethod } from '@/types/hosted-consents/hosted-consent.types';

const props = defineProps<{
  value: ConsentMethod | null;
}>();
const emit = defineEmits(['change']);

function onSelectConsentMethod(event: any) {
  emit('change', event.target.value === 'null' ? null : event.target.value);
}
</script>

<template>
  <select
    @change="onSelectConsentMethod($event)"
    class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
    <option value="null">-- Select a consent method --</option>
    <option
      :selected="consentMethod === props.value"
      v-for="consentMethod in ConsentMethod"
      :key="consentMethod"
      :value="consentMethod">
      {{ consentMethod }}
    </option>
  </select>
</template>
