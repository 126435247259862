<script setup lang="ts">
const props = defineProps<{
  title: string;
  subtitle?: string;
  buttonLink?: string;
  buttonLinkText?: string;
  method: string;
  endpoint: string;
}>();

const api = import.meta.env.VITE_JWT_AUDIENCE + '/v1';
</script>

<template>
  <div class="mb-1 flex justify-between items-start">
    <div>
      <h1 class="text-2xl font-semibold">{{ props.title }}</h1>
      <span class="text-sm text-gray-700" v-if="props.subtitle">{{ props.subtitle }}</span>
    </div>

    <div v-if="buttonLink !== undefined" class="flex border-0 justify-end">
      <RouterLink
        :to="{
          path: props.buttonLink as string,
        }"
        class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
        {{ props.buttonLinkText }}
      </RouterLink>
    </div>
  </div>
  <div class="text-sm font-sm text-gray-500 mb-2 mt-2">
    <button
      class="bg-green-200 rounded-full text-xs font-xs px-2 mr-2"
      :class="props.method.toUpperCase() === 'DELETE' ? 'bg-red-200' : ''">
      {{ props.method.toUpperCase() }}</button
    >{{ api }}{{ props.endpoint }}
  </div>
</template>
