export type ParamsMap = Record<string, string>;

export type QueryParams = Record<string, any>;

const createUrlWithParams = (urlBase: string, paramsMap: ParamsMap, queryParams: QueryParams) => {
  let url: string = urlBase;
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      url = url + `${paramsMap[key as keyof typeof paramsMap]}=${value}&`;
    }
  });
  return url;
};

export default createUrlWithParams;
