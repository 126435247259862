<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import { ApiClient } from '@/helpers/apiClient';
import { onMounted, ref } from 'vue';
import type { Customer } from '@/types/customer';

const userStore = useUserStore();
const apiClient = new ApiClient();

const emit = defineEmits(['change']);
const props = defineProps<{
  value: string;
}>();
const customers = ref<Customer[]>([]);

onMounted(async () => {
  /**
   * @todo We'll need to think of a better way of doing this if a user ends up with more than 500 customers.
   */
  const response = await apiClient
    .getCustomers({
      apiKey: userStore.currentApiKey,
      pageSize: 500,
      currentPage: 0,
    })
    .catch(() => {
      return { customers: [], hasMore: false };
    });
  customers.value = response.customers;
});

function onSelectCustomer(event: any) {
  emit('change', event.target.value);
}
</script>

<template>
  <select
    @change="onSelectCustomer($event)"
    class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
    <option value="null">-- Select a customer --</option>
    <option
      v-for="customer in customers"
      :value="customer.id"
      :key="customer.id"
      :selected="customer.id === props.value">
      {{ customer.name }} ({{ customer.email }})
    </option>
  </select>
</template>
