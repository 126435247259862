import zod from 'zod';

export enum UKDataSource {
  electralink = 'electralink',
  dcc = 'dcc',
}

export const ElectralinkDataSourceInfo = zod.null().or(zod.undefined());
export type ElectralinkDataSourceInfo = zod.infer<typeof ElectralinkDataSourceInfo>;

export const DCCDataSourceInfo = zod.null().or(zod.undefined());
export type DCCDataSourceInfo = zod.infer<typeof DCCDataSourceInfo>;

export type UKDataSourceInfo = ElectralinkDataSourceInfo | DCCDataSourceInfo;

export const UKDataSourceMapping: Record<UKDataSource, zod.Schema> = {
  [UKDataSource.electralink]: ElectralinkDataSourceInfo,
  [UKDataSource.dcc]: DCCDataSourceInfo,
};
