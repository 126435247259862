import zod from 'zod';

export enum NLDataSource {
  anexo = 'anexo',
  edsn = 'edsn',
  joulz = 'joulz',
  kenter = 'kenter',
  meetbedrijf = 'meetbedrijf',
  solaredge = 'solaredge',
}

export enum MeterType {
  gas = 'gas',
  electricity = 'electricity',
}

export const MeterChannel = zod
  .object({
    code: zod.string(),
    url: zod.string(),
  })
  .strict();

export type MeterChannel = zod.infer<typeof MeterChannel>;

const BaseNLDataSourceInfo = zod.object({
  postal_code: zod.string(),
  house_number: zod.string(),
  meter_type: zod.nativeEnum(MeterType),
  channels: zod.array(MeterChannel).optional(),
  status: zod.enum(['pending', 'verified']).optional(),
});

export const AnexoDataSourceInfo = BaseNLDataSourceInfo.extend({
  meter_id: zod.string(),
  client_id: zod.string(),
  client_secret: zod.string(),
}).strict();

export type AnexoDataSourceInfo = zod.infer<typeof AnexoDataSourceInfo>;

export const EdsnDataSourceInfo = BaseNLDataSourceInfo.extend({
  meter_number: zod.string(),
}).strict();

export type EdsnDataSourceInfo = zod.infer<typeof EdsnDataSourceInfo>;

export const JoulzDataSourceInfo = BaseNLDataSourceInfo.extend({
  api_key: zod.string(),
  subcode: zod.string(),
}).strict();

export type JoulzDataSourceInfo = zod.infer<typeof JoulzDataSourceInfo>;

export const KenterDataSourceInfo = BaseNLDataSourceInfo.extend({
  username: zod.string(),
  password: zod.string(),
}).strict();

export type KenterDataSourceInfo = zod.infer<typeof KenterDataSourceInfo>;

export const MeetbedrijfDataSourceInfo = BaseNLDataSourceInfo.extend({
  api_key: zod.string(),
  meter_id: zod.string(),
}).strict();

export type MeetbedrijfDataSourceInfo = zod.infer<typeof MeetbedrijfDataSourceInfo>;

export const SolarEdgeDataSourceInfo = BaseNLDataSourceInfo.extend({
  api_key: zod.string(),
  meter_id: zod.string(),
}).strict();

export type SolarEdgeDataSourceInfo = zod.infer<typeof SolarEdgeDataSourceInfo>;

export type NLDataSourceInfo =
  | AnexoDataSourceInfo
  | EdsnDataSourceInfo
  | JoulzDataSourceInfo
  | KenterDataSourceInfo
  | MeetbedrijfDataSourceInfo
  | SolarEdgeDataSourceInfo;

export const NLDataSourceMapping: Record<NLDataSource, zod.Schema> = {
  [NLDataSource.anexo]: AnexoDataSourceInfo,
  [NLDataSource.edsn]: EdsnDataSourceInfo,
  [NLDataSource.joulz]: JoulzDataSourceInfo,
  [NLDataSource.kenter]: KenterDataSourceInfo,
  [NLDataSource.meetbedrijf]: MeetbedrijfDataSourceInfo,
  [NLDataSource.solaredge]: SolarEdgeDataSourceInfo,
};
