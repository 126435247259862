<script setup lang="ts">
import {
  type EmailSubscriptionResponse,
  SubscriptionType,
  type WebhookSubscriptionResponse,
} from '@/types/subscriptions/subscription.types';
import { useRouter } from 'vue-router';
import Button from '@/components/Button/Button.vue';

const props = defineProps<{
  subscriptions: (WebhookSubscriptionResponse | EmailSubscriptionResponse)[];
  type: SubscriptionType;
}>();

const router = useRouter();
</script>

<template>
  <table class="divide-y w-full divide-gray-300">
    <thead>
      <tr>
        <th scope="col" class="px-3 pl-0 py-3.5 text-left text-sm font-semibold text-gray-900 w-60">
          {{ type === SubscriptionType.EMAIL ? 'Email Address' : 'URL' }}
        </th>
        <th scope="col" class="px-3 pl-0 py-3.5 text-left text-sm font-semibold text-gray-900 w-80">
          Events
        </th>
        <th
          scope="col"
          class="px-3 pl-0 py-3.5 text-left text-sm font-semibold text-gray-900 w-fit">
          Description
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <tr v-if="props.subscriptions.length === 0">
        <td colspan="6" class="text-center py-8 text-gray-500">
          There are no subscriptions to show here.
        </td>
      </tr>
      <tr v-for="subscription in props.subscriptions" :key="subscription.id">
        <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0 w-60">
          <div class="text-gray-900">
            {{
              type === SubscriptionType.EMAIL
                ? (subscription as EmailSubscriptionResponse).email
                : (subscription as WebhookSubscriptionResponse).url
            }}
          </div>
        </td>
        <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0 w-80">
          <span
            v-for="event in subscription.events"
            :key="event"
            class="inline-flex items-center rounded-md px-2 m-1 py-1 text-xs font-medium ring-1 ring-inset bg-blue-50 text-blue-700 ring-blue-600/20"
            >{{ event }}</span
          >
        </td>
        <td class="py-5 pl-4 pr-3 text-sm sm:pl-0 w-fit">
          <div class="text-gray-900 text-ellipsis overflow-hidden">
            {{ subscription.description }}
          </div>
        </td>
        <td class="text-right">
          <Button
            text="View"
            @click="router.push(`/event-subscriptions/${type}/${subscription.id}`)" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
