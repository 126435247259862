<script setup lang="ts">
import type { ApiRequest } from '@/types/apiRequests';
import { useTimeAgo } from '@vueuse/core';
import MethodChip from '@/components/Chips/MethodChip/MethodChip.vue';
import { computed } from 'vue';
import { useHomepageStore } from '@/stores/homepageStore';

const props = defineProps<{
  request: ApiRequest;
  asHeader: boolean;
}>();

const homepageStore = useHomepageStore();

const emit = defineEmits(['click']);

const success = computed(() => {
  return props.request.status_code === undefined || props.request.status_code < 400;
});
const timeAgo = computed(() => {
  return useTimeAgo(new Date(props.request.created_at)).value;
});

function handleSelectRequest() {
  emit('click');
}
</script>

<template>
  <div
    @click="handleSelectRequest"
    :class="[
      'p-4 border border-gray-300 bg-white border-l-4 flex justify-between rounded-r align-top shadow',
      !asHeader ? 'hover:cursor-pointer hover:bg-slate-50' : '',
      success ? 'border-l-green-500' : 'border-l-red-500',
      !asHeader && homepageStore.currentSelectedRequest?.id === props.request.id ? 'selected' : '',
    ]">
    <div class="flex rounded-r text-xs flex-col">
      <span class="text-gray-400"
        >{{ timeAgo }} @
        {{
          new Date(request.created_at).toLocaleTimeString('default', {
            timeStyle: 'short',
          })
        }}</span
      >
      <span>{{ request.origin }}{{ request.path }}</span>
    </div>
    <MethodChip :method="request.method" />
  </div>
</template>

<style scoped>
.selected {
  background-color: rgb(238 240 242 / var(--tw-bg-opacity));
}
</style>
