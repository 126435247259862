<template>
  <div class="w-full">
    <ApiPageHeader title="Create a new meter" endpoint="/meters" method="POST" />
    <form class="my-4 border-t border-gray-300 pt-4">
      <div
        class="space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >meter_number <Required /> </span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The meter number</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="meterNumber" />
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >customer_id <Required /> </span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The id of the customer</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <select
                name="customer_id"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="customerId"
                :disabled="!allCustomers?.length"
                @change="filterConsents()">
                <option v-if="!allCustomers?.length" value="">
                  You have to create a customer first
                </option>
                <option v-for="customer in allCustomers" :key="customer.id" :value="customer.id">
                  {{ customer.id }} ({{ customer.name }})
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >hosted_consent_id</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The id of the hosted consent</div>
          </div>
          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <select
                name="hosted_consent_id"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="hostedConsentId"
                :disabled="!allHostedConsents?.length">
                <option v-if="!allCustomers.length" value="" disabled>
                  You need customers to see Hosted Consents
                </option>
                <option v-if="!allHostedConsents.length" value="" disabled>
                  You need to create a Hosted Consent
                </option>
                <option
                  v-for="hc in allHostedConsents"
                  :key="hc.id"
                  :value="hc.id"
                  :disabled="!!hc.revoked_at || !hc.valid">
                  {{ hc.id }}
                  {{
                    hc.revoked_at
                      ? '(Revoked)'
                      : !hc.valid
                        ? '(Invalid)'
                        : `(${hc.description ? `${hc.description} - ` : ''}${hc.flow_type})`
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Meter type -->
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >meter_type</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The type of meter you wish to create</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <select
                name="hosted_consent_id"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="meterType">
                <option value="" disabled>Please select a meter type</option>
                <option value="electricity">Electricity</option>
                <option value="gas">Gas</option>
              </select>
            </div>
          </div>
        </div>

        <!-- Meter Description -->
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >description</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The description of the meter</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="description" />
            </div>
          </div>
        </div>

        <!-- Meter Address -->
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >address</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The address of the meter</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="address" />
            </div>
          </div>
        </div>

        <!-- One time data pull -->
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2 items-center">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >single_fetch</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">boolean</span>
            </div>

            <div class="text-sm font-sm text-gray-600">
              A meter that has this value set to true will do a one-off data pull, but will not
              automatically update every night with new data.
            </div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0 h-full flex items-center">
            <input class="w-6 h-6" type="checkbox" v-model="singleFetch" />
          </div>
        </div>
      </div>
      <div class="flex border-0 justify-end">
        <button
          type="button"
          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          @click="onSubmit">
          Submit
        </button>
        <ConfirmAction
          v-if="isConfirmModalOpen"
          title="Create Meter"
          message="Warning! If you create a meter without a hosted_consent_id, it's status will be 'inactive' until one is provided"
          confirmButtonText="Create Meter"
          @confirm="createMeter"
          @close="closeCreateConfirmation" />
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import ConfirmAction from '@/components/ConfirmAction/index.vue';
import Required from '@/components/Required/Required.vue';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import type { Customer } from '@/types/customer';
import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const meterNumber = ref<string>('');
const address = ref<string>('');
const customerId = ref<string>('');
const hostedConsentId = ref<string>('');
const description = ref<string>('');
const isConfirmModalOpen = ref<boolean>(false);

const meterType = ref<null | string>(null);
const singleFetch = ref<boolean>(false);

const toast = useToast();
const allCustomers = ref<Customer[]>([]);
const allHostedConsents = ref<HostedConsentModel[]>([]);

const apiClient = new ApiClient();
const userStore = useUserStore();
const router = useRouter();

const customerResponse = await apiClient
  .getCustomers({
    apiKey: userStore.currentApiKey,
    pageSize: 10000,
    currentPage: 1,
  })
  .catch(() => {
    return { customers: [], hasMore: false };
  });
const hostedConsentResponse = await apiClient
  .getHostedConsents(userStore.currentApiKey)
  .catch(() => null);

if (customerResponse?.customers?.length) {
  allCustomers.value = customerResponse.customers;
  customerId.value = customerResponse.customers[0].id;
}

if (hostedConsentResponse?.length) {
  filterConsents();
}

function filterConsents() {
  allHostedConsents.value = hostedConsentResponse
    .filter((hc: HostedConsentModel) => hc.customer?.id === customerId.value)
    .sort(
      (a: HostedConsentModel, b: HostedConsentModel) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );

  if (!allHostedConsents.value.length || !allHostedConsents.value[0].valid) {
    return (hostedConsentId.value = '');
  }

  hostedConsentId.value = allHostedConsents.value[0].id;
}

async function onSubmit() {
  if (hostedConsentId.value === '') {
    openCreateConfirmation();
  } else {
    createMeter();
  }
}

function openCreateConfirmation() {
  isConfirmModalOpen.value = true;
}

function closeCreateConfirmation() {
  isConfirmModalOpen.value = false;
}

async function createMeter() {
  await apiClient
    .createNewMeter({
      meter_number: meterNumber.value,
      address: address.value,
      customer_id: customerId.value,
      hosted_consent_id: hostedConsentId.value,
      description: description.value,
      apiKey: userStore.currentApiKey,
      meter_type: meterType.value,
      single_fetch: singleFetch.value,
    })
    .then(() => {
      toast.success('Meter created');
      router.push('/meters');
    })
    .catch(e => {
      toast.error(e.errorMessage);
    });
}
</script>
