<script setup lang="ts">
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import { onMounted, ref } from 'vue';
import { useNotificationAccountStore } from '@/stores/notificationAccountStore';
import type { TriggerEventResponse } from '@/types/events/event.types';
import { useRoute, useRouter } from 'vue-router';
import CopyToClipboardButton from '@/components/CopyToClipboardButton/index.vue';
import Button from '@/components/Button/Button.vue';

const route = useRoute();
const router = useRouter();
const notificationAccountStore = useNotificationAccountStore();
const accountEvent = ref<TriggerEventResponse | null>(null);

onMounted(async () => {
  const id = route.params.id as string;
  accountEvent.value = await notificationAccountStore.getAccountEvent(id);
});
</script>
<script lang="ts">
import hljsVuePlugin from '@highlightjs/vue-plugin';

export default { components: { highlightjs: hljsVuePlugin.component } };
</script>

<template>
  <div :key="accountEvent?.id" v-if="accountEvent !== null">
    <ApiPageHeader
      title="Account Event"
      :endpoint="`/notification-accounts/events/${accountEvent?.id}`"
      method="get" />
    <div class="mt-4 pt-4 border-t border-gray-300"></div>
    <div v-if="accountEvent" class="divide-y divide-gray-100 border-t border-b border-gray-100">
      <div class="py-2 grid grid-cols-4 items-center gap-2">
        <div class="flex flex-col">
          <span>Event Id</span>
          <span class="text-gray-400">The id of this event</span>
        </div>
        <span>{{ accountEvent.id }}</span>
        <span />
        <div>
          <CopyToClipboardButton :toCopy="accountEvent.id" name="Event Id" />
        </div>
      </div>
      <div class="py-2 grid grid-cols-4 items-center gap-2">
        <div class="flex flex-col">
          <span>Description</span>
          <span class="text-gray-400">Details about this event</span>
        </div>
        <span>{{ accountEvent.description }}</span>
      </div>
      <div class="py-2 grid grid-cols-4 items-center gap-2">
        <div class="flex flex-col">
          <span>Event Type</span>
          <span class="text-gray-400">The type of the event that occurred</span>
        </div>
        <span>{{ accountEvent.event_id }}</span>
      </div>
      <div class="py-2 grid grid-cols-4 items-center gap-2">
        <div class="flex flex-col">
          <span>Date</span>
          <span class="text-gray-400">When the event happened</span>
        </div>
        <span>{{ new Date(accountEvent.created_at).toLocaleString() }}</span>
      </div>
      <div class="py-2 grid grid-cols-4 items-start gap-2">
        <div class="flex flex-col">
          <span>Payload</span>
          <span class="text-gray-400">The description of this subscription</span>
        </div>
        <div class="overflow-y-auto h-120 col-span-2">
          <highlightjs language="json" :code="JSON.stringify(accountEvent.payload, null, 2)" />
        </div>
        <div>
          <CopyToClipboardButton
            :toCopy="JSON.stringify(accountEvent.payload, null, 2)"
            name="Payload" />
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4 flex justify-end space-x-1">
    <Button text="Close" @click="router.push(`/event-subscriptions`)" />
  </div>
</template>
