import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';
import { ConsentFlowType } from '@/types/hosted-consents/hosted-consent.types';

export enum ConsentEvidenceStatus {
  PENDING = 'pending',
  MANUAL_REVIEW = 'manual-review',
  AUTOMATIC_APPROVED = 'automatic-approved',
  MANUAL_APPROVED = 'manual-approved',
  REJECTED = 'rejected',
}

export enum ConsentStatus {
  VALID = 'valid',
  PENDING = 'pending',
  INVALID = 'invalid',
  EXPIRED = 'expired',
  REVOKED = 'revoked',
}

/**
 * reusable function which can take a consent and return its "status" for the frontend.
 *
 * Worth noting that statuses area frontend only concept and aren't used at all on the backend so this is purely visual
 * grouping to make consents easier to understand for users.
 * @param consent
 */
export function status(consent: HostedConsentModel) {
  /**
   * In the case of the UK residential flow, we could have a technically valid
   * hosted consent but its in manual review.
   */
  if (
    consent.flow_type === ConsentFlowType.UK_RESIDENTIAL_SPECIFIC &&
    consent.evidence_document_metadata &&
    consent.evidence_document_metadata.review_status
  ) {
    switch (consent.evidence_document_metadata.review_status) {
      case ConsentEvidenceStatus.MANUAL_REVIEW:
        return ConsentStatus.PENDING;
      case ConsentEvidenceStatus.REJECTED:
        return ConsentStatus.INVALID;
    }
  }

  if (consent.valid) {
    return ConsentStatus.VALID;
  }
  if (
    new Date(consent.expiry) > new Date() &&
    new Date(consent.link_expiration_date) > new Date() &&
    !consent.valid &&
    !consent.revoked_at
  ) {
    return ConsentStatus.PENDING;
  }

  if (
    new Date(consent.expiry) < new Date() ||
    new Date(consent.link_expiration_date) < new Date() ||
    consent.revoked_at
  ) {
    if (consent.revoked_at) {
      return ConsentStatus.REVOKED;
    }
    return ConsentStatus.EXPIRED;
  }

  return '';
}
