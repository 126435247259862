<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog
      as="div"
      static
      class="fixed inset-0 flex z-40 md:hidden"
      @close="closeSidebar"
      :open="isOpen">
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full">
        <div class="relative flex-1 flex flex-col w-full pb-4 bg-gray-800">
          <div class="flex justify-between items-start h-14 px-4 py-3">
            <a class="h-full" href="/">
              <img class="w-auto h-full block" :src="logoUrl" alt="Openvolt" />
            </a>

            <button class="block" @click="closeSidebar">
              <span class="sr-only">Close sidebar</span>
              <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
          <div class="flex-1 flex flex-col overflow-y-auto">
            <Listbox>
              <ListboxButton class="bg-gray-200 py-2 text-medium font-medium text-gray-800">{{
                userStore.currentAccount.name
              }}</ListboxButton>
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0">
                <ListboxOptions class="bg-gray-200 divide-y divide-gray-300">
                  <ListboxOption
                    v-for="account in userStore.accountsList"
                    :key="account.name"
                    class="py-2 px-3 text-sm font-medium text-gray-800"
                    :value="account.name"
                    @click="handleAccountClick(account)">
                    {{ account.name }}
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </Listbox>
            <!-- <Menu
                            as="div"
                            class="z-40 relative inline-block text-left h-16 w-auto bg-gray-800"
                        >
                            <div>
                                <MenuButton
                                    class="inline-flex py-4 w-full rounded-md border border-gray-800 shadow-sm px-4 py-2 bg-gray-800 text-medium font-sm text-gray-300 hover:bg-gray-800 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-800 focus:offset-gray-800"
                                >
                                    <OfficeBuildingIcon
                                        class="-mr-1 ml-2 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    <p class="px-4">accountName</p>
                                    <ChevronDownIcon
                                        class="-mr-1 ml-2 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </MenuButton>
                            </div>

                            <transition>
                                <MenuItems
                                    class="origin-bottom-left relative w-56 -y-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                                >
                                    <MenuItem
                                        v-for="account in accountList"
                                        :key="account.name"
                                        @click="handleAccountClick"
                                        v-slot="{ active }"
                                    >
                                        <a
                                            href="#"
                                            :class="[
                                                active
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-700',
                                                'group flex items-center px-4 py-2 text-sm'
                                            ]"
                                        >
                                            <OfficeBuildingIcon
                                                class="z-40 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                            />
                                            {{ account.name }}
                                        </a>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu> -->
            <nav class="flex-1 px-2 py-0 bg-gray-800 space-y-1">
              <router-link
                v-for="item in navigationItems"
                :key="item.name"
                :to="item.href"
                @:click="setActive"
                :class="[
                  item.isActive
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-slate-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                ]">
                <component
                  :is="item.icon"
                  :class="[
                    item.isActive ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                    'mr-3 h-6 w-6',
                  ]"
                  aria-hidden="true" />
                {{ item.name }}</router-link
              >

              <a
                href="https://docs.openvolt.com/reference/introduction"
                class="text-gray-300 hover:bg-slate-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md pt-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                </svg>
                <span class="ml-3">Reference API</span></a
              >
            </nav>
          </div>
          <div class="bg-slate-700 p-4">
            <div class="mb-10">
              <router-link
                to="/account-settings"
                class="text-gray-300 hover:bg-slate-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                v-on:click="closeSidebar()">
                <CogIcon class="h-6 w-6" />
                <span class="ml-3">Account Settings</span></router-link
              >
            </div>
            <div class="z-50 ml-3 relative" v-if="isAuthenticated">
              <div class="flex items-center">
                <div>
                  <img class="inline-block h-10 w-10 rounded-full" :src="user?.picture" alt="" />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-white">
                    {{ user?.name }}
                  </p>
                  <div @click="auth0Logout">
                    >
                    <p class="z-0 text-xs font-medium text-gray-300 group-hover:text-gray-200">
                      Logout
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import logoUrl from '@/assets/images/openvolt-white.png';
import { useUserStore } from '@/stores/user';
import type { Account } from '@/types/user';
import {
  Dialog,
  TransitionChild,
  TransitionRoot,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue';

import { XMarkIcon, CogIcon } from '@heroicons/vue/24/solid';

import { useAuth0 } from '@auth0/auth0-vue';
import { useNavigation } from '@/composables/useNavigation';

interface Props {
  isOpen: boolean;
}

defineProps<Props>();
const { isAuthenticated, user, logout } = useAuth0();
const userStore = useUserStore();
const { navigationItems, setActive } = useNavigation();

function handleAccountClick(account: Account) {
  changeSessionAccount(account);
  closeSidebar();
}

function closeSidebar() {
  emit('close');
}

function changeSessionAccount(account: Account) {
  userStore.handleAccountChange(account);
}
function auth0Logout() {
  logout({ logoutParams: { returnTo: window.location.origin } });
  userStore.handleLogout();
}

const emit = defineEmits(['close']);
</script>
