export const formatDataSource = (dataSource: string): string => {
  switch (dataSource) {
    case 'dcc':
      return 'DCC';
    default:
      return dataSource
        ? dataSource.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase())
        : 'None';
  }
};
