import { createRouter, createWebHistory } from 'vue-router';
import Customers from '@/views/Customers/Customers.vue';
import CustomerDetail from '@/views/Customers/CustomerDetail.vue';
import CustomerCreate from '@/views/Customers/CustomerCreate.vue';
import Meters from '@/views/Meters/Meters.vue';
import MeterDetail from '@/views/Meters/MeterDetail.vue';
import MeterCreate from '@/views/Meters/MeterCreate.vue';
import Inverters from '@/views/Inverters/Inverters.vue';
import InverterDetail from '@/views/Inverters/InverterDetail.vue';
import InverterCreate from '@/views/Inverters/InverterCreate.vue';
import HostedConsents from '@/views/HostedConsents/HostedConsents.vue';
import CreateHostedConsent from '@/views/HostedConsents/CreateHostedConsent.vue';
import HostedConsent from '@/views/HostedConsents/HostedConsent.vue';
import EventSubscription from '@/views/EventSubscriptions/EventSubscription.vue';
import EditEventSubscription from '@/views/EventSubscriptions/EditEventSubscription.vue';
import EventSubscriptions from '@/views/EventSubscriptions/EventSubscriptions.vue';
import AccountEvent from '@/views/EventSubscriptions/AccountEvent.vue';
import Home from '@/views/Home/Home.vue';
import DeveloperDashboard from '@/views/Developer Dashboard/Developer Dashboard.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: '',
      path: '/',
      component: Home,
    },
    {
      name: 'developer dashboard',
      path: '/developer-dashboard',
      component: DeveloperDashboard,
    },
    {
      name: 'meters',
      path: '/meters',
      component: Meters,
    },
    {
      name: 'meter-detail',
      path: '/meters/:id',
      component: MeterDetail,
    },
    {
      name: 'meter-create',
      path: '/meters/new',
      component: MeterCreate,
    },
    {
      name: 'inverters',
      path: '/inverters',
      component: Inverters,
    },
    {
      name: 'inverter-detail',
      path: '/inverters/:id',
      component: InverterDetail,
    },
    {
      name: 'inverter-create',
      path: '/inverters/new',
      component: InverterCreate,
    },
    {
      name: 'customers',
      path: '/customers',
      component: Customers,
    },
    {
      name: 'customer-detail',
      path: '/customers/:id',
      component: CustomerDetail,
    },
    {
      name: 'customer-create',
      path: '/customers/new',
      component: CustomerCreate,
    },
    {
      name: 'event-subscription',
      path: '/event-subscriptions',
      component: EventSubscriptions,
    },

    {
      name: 'event-subscription-create',
      path: '/event-subscriptions/new',
      component: EditEventSubscription,
    },

    {
      name: 'event-subscription-detail',
      path: '/event-subscriptions/:type/:id',
      component: EventSubscription,
    },

    {
      name: 'event-subscription-edit',
      path: '/event-subscriptions/:type/:id/edit',
      component: EditEventSubscription,
    },

    {
      name: 'account-event',
      path: '/account-event/:id',
      component: AccountEvent,
    },

    /**
     * Hosted Consents
     */
    {
      name: 'hosted-consents',
      path: '/hosted-consents',
      component: HostedConsents,
    },
    {
      name: 'hosted-consent',
      path: '/hosted-consents/:id',
      component: HostedConsent,
    },
    {
      name: 'hosted-consents-create',
      path: '/hosted-consents/new',
      component: CreateHostedConsent,
    },
  ],
});

export default router;
