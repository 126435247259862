<script setup lang="ts">
const props = defineProps<{
  disabled?: boolean;
  text: string;
  delete?: boolean;
}>();

const emit = defineEmits(['click']);

function handleClick() {
  emit('click');
}
</script>

<template>
  <button
    @click="handleClick"
    class="rounded border-none border px-4 py-2 text-md shadow"
    :class="[
      !props.delete && !props.disabled ? 'text-white bg-blue-600 hover:bg-blue-500' : '',
      props.delete ? 'bg-red-700 text-white' : '',
      props.disabled ? 'text-gray-400 bg-gray-200 cursor-not-allowed' : '',
    ]">
    {{ props.text }}
  </button>
</template>

<style scoped></style>
