<template>
  <div class="m-4 w-full flex items-start">
    <template
      v-if="
        isUserRegistered && isAuthenticated && userStore.currentAccount && userStore.currentApiKey
      ">
      <div class="background">
        <img :src="background" />
      </div>
      <DesktopSidebar :key="userStore.currentAccount.id"></DesktopSidebar>
      <MobileSidebar :isOpen="isMobileSidebarOpen" @close="closeSidebar"></MobileSidebar>
      <div class="flex-1 bg-white z-10 relative shadow border-gray-200 border rounded-lg h-full">
        <div
          v-if="isAuthenticated && !isLoading"
          :class="[
            'w-full',
            noPadRoutes.indexOf(currentRoute.path) === -1 ? 'px-32 py-16' : 'p-8',
          ]">
          <Suspense>
            <router-view></router-view>
          </Suspense>
        </div>
      </div>
    </template>
    <LoginPage v-if="!isAuthenticated && !isLoading" />
    <ClosedBetaPage v-else-if="!isUserRegistered && isAuthenticated" />
    <div class="flex overflow-hidden bg-gray-100 justify-center">
      <LoadingWidget :isOpen="isLoading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { RouterView, useRouter } from 'vue-router';
import { computed, ref, watch, watchEffect } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import LoginPage from './views/LoginPage.vue';
import DesktopSidebar from './components/DesktopSidebar/index.vue';
import MobileSidebar from './components/MobileSidebar/index.vue';
import LoadingWidget from './components/LoadingWidget/index.vue';
import ClosedBetaPage from './views/ClosedBetaPage.vue';
import { useUserStore } from './stores/user';
import background from '@/assets/images/background-gradient.svg';

const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();

const userStore = useUserStore();
const { currentRoute } = useRouter();

watch(isAuthenticated, async () => {
  if (isAuthenticated) {
    userStore.setUserAccessToken(await getAccessTokenSilently());
  }
});

const isMobileSidebarOpen = ref<boolean>(false);

const noPadRoutes = ['/', '/developer-dashboard'];

function closeSidebar(): void {
  isMobileSidebarOpen.value = false;
}

const isUserRegistered = computed<boolean>(() => !!userStore.user.email);

watchEffect(async () => {
  if (
    isAuthenticated.value &&
    !isLoading.value &&
    user.value?.email &&
    userStore.user.accessToken
  ) {
    await userStore.handleUserInit(user.value?.email);
  }
});
</script>

<style scoped>
input {
  color: rgb(17 24 39);
}

.background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: start;
  align-items: flex-end;
}
</style>
