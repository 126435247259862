<script setup lang="ts">
import type { ConsentFlowType } from '@/types/hosted-consents/hosted-consent.types';

const props = defineProps<{
  flowType: ConsentFlowType;
}>();
</script>

<template>
  <span
    class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-blue-50 text-blue-700 ring-blue-600/20"
    >{{ props.flowType }}</span
  >
</template>

<style scoped></style>
