import { defineStore } from 'pinia';
import { ApiClient } from '@/helpers/apiClient';
import { ref } from 'vue';
import { useUserStore } from '@/stores/user';
import type { ApiRequest } from '@/types/apiRequests';
import { updateRef } from '@/helpers/refHelper';
import type { TriggerEventResponse } from '@/types/events/event.types';
import type { Meter } from '@/types/meter';

const apiClient = new ApiClient();
export interface ToplineStats {
  meters: number;
  customers: number;
  hostedConsents: number;
}

export interface ActivityDay {
  date: string;
  day: number;
  requests: { success: ApiRequest[]; failed: ApiRequest[] };
}
export const useHomepageStore = defineStore('HomepageStore', () => {
  const userStore = useUserStore();

  const stats = ref<null | ToplineStats>(null);
  const apiRequests = ref<Array<{ date: string; requests: ApiRequest[] }> | null>(null);
  const allMeters = ref<Meter[]>([]);

  const currentSelectedDay = ref<null | ActivityDay>(null);
  const currentSelectedRequest = ref<null | ApiRequest>(null);
  const currentSelectedEvent = ref<null | TriggerEventResponse>(null);

  async function getStats() {
    stats.value = await apiClient.getStats(userStore.currentAccount.id, userStore.user.accessToken);
  }

  async function getApiRequests() {
    const requests = await apiClient.getApiRequests(
      userStore.currentAccount.id,
      userStore.user.accessToken,
    );
    if (requests) {
      apiRequests.value = requests;
    }
  }

  async function getApiRequest(id: string) {
    const request = await apiClient.getApiRequest(
      userStore.currentAccount.id,
      id,
      userStore.user.accessToken,
    );
    if (request) {
      currentSelectedRequest.value = request;
    }
  }

  async function getEvent(eventId: string) {
    return await updateRef(
      apiClient.getAccountEvent(userStore.currentApiKey, eventId),
      currentSelectedEvent,
    );
  }

  async function getAllMeters() {
    const response = await apiClient
      .getMeters({
        currentPage: 1,
        pageSize: 1000,
        apiKey: userStore.currentApiKey,
      })
      .catch(() => {
        return { meters: [], totalCount: 0 };
      });
    allMeters.value = response.meters;
  }

  return {
    stats,
    getStats,
    allMeters,
    apiRequests,

    currentSelectedDay,
    currentSelectedRequest,
    currentSelectedEvent,

    getApiRequests,
    getApiRequest,
    getEvent,
    getAllMeters,
  };
});
