import { defineStore } from 'pinia';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import { ref } from 'vue';
import type {
  CreateEmailSubscriptionRequest,
  CreateWebhookSubscriptionRequest,
  EmailSubscriptionResponse,
  UpdateEmailSubscriptionRequest,
  UpdateWebhookSubscriptionRequest,
  WebhookSubscriptionResponse,
} from '@/types/subscriptions/subscription.types';
import { SubscriptionType } from '@/types/subscriptions/subscription.types';
import { updateRef } from '@/helpers/refHelper';

const api = new ApiClient();

export const useSubscriptionStore = defineStore('SubscriptionStore', () => {
  const userStore = useUserStore();
  const subscription = ref<EmailSubscriptionResponse | WebhookSubscriptionResponse | null>(null);
  const webhookSubscriptions = ref<WebhookSubscriptionResponse[]>([]);
  const emailSubscriptions = ref<EmailSubscriptionResponse[]>([]);

  async function getSubscription(
    id: string,
    type: SubscriptionType,
  ): Promise<WebhookSubscriptionResponse | EmailSubscriptionResponse> {
    return (await updateRef(
      type === SubscriptionType.EMAIL
        ? api.getEmailSubscription(userStore.currentApiKey, id)
        : api.getWebhookSubscription(userStore.currentApiKey, id),
      subscription,
    ))!;
  }

  async function deleteSubscription(id: string, type: SubscriptionType) {
    return updateRef(
      type === SubscriptionType.EMAIL
        ? api.deleteEmailSubscription(userStore.currentApiKey, id)
        : api.deleteWebhookSubscription(userStore.currentApiKey, id),
      subscription,
    );
  }

  async function createSubscription(
    subscriptionData: CreateWebhookSubscriptionRequest | CreateEmailSubscriptionRequest,
    type: SubscriptionType,
  ) {
    return updateRef(
      type === SubscriptionType.EMAIL
        ? api.createEmailSubscription(
            userStore.currentApiKey,
            subscriptionData as CreateEmailSubscriptionRequest,
          )
        : api.createWebhookSubscription(
            userStore.currentApiKey,
            subscriptionData as CreateWebhookSubscriptionRequest,
          ),
      subscription,
    );
  }

  async function updateSubscription(
    id: string,
    subscriptionData: UpdateWebhookSubscriptionRequest | UpdateEmailSubscriptionRequest,
    type: SubscriptionType,
  ) {
    return updateRef(
      type === SubscriptionType.EMAIL
        ? api.updateEmailSubscription(userStore.currentApiKey, id, subscriptionData)
        : api.updateWebhookSubscription(userStore.currentApiKey, id, subscriptionData),
      subscription,
    );
  }

  return {
    subscription,
    webhookSubscriptions,
    emailSubscriptions,
    getSubscription,
    createSubscription,
    updateSubscription,
    deleteSubscription,
  };
});
