import { ref, computed } from 'vue';
import type { ComputedRef } from 'vue';
import { defineStore } from 'pinia';
import type { User, UserAlert, Account } from '@/types/user';
import { ApiClient } from '@/helpers/apiClient';
import { useToast } from 'vue-toastification';
import type { ImageMetadata } from '@/types/common.types';

const apiClient = new ApiClient();
const toast = useToast();

export const useUserStore = defineStore('user', () => {
  //state

  const userAlert = ref<UserAlert>({
    visible: false,
    alert_type: '',
    message: '',
  });

  const user = ref<User>({
    email: '',
    id: '',
    accounts: [],
    accessToken: '',
  });

  const currentAccount = ref<Account>({
    name: '',
    role: '',
    id: '',
    apiKey: '',
    feature_flags: [],
    testApiKey: '',
    liveApiKey: '',
    theme: '',
    logo: '',
    logo_metadata: {
      name: '',
      file_type: '',
      size: 0,
    },
  });

  const accountsList: ComputedRef<Account[]> = computed(() => {
    return user.value.accounts;
  });

  const featureFlagList: ComputedRef<string[]> = computed(() => {
    return currentAccount.value.feature_flags || [];
  });

  const currentApiKey: ComputedRef<string> = computed(() => {
    return currentAccount.value.liveApiKey;
  });

  const isOpenvoltUser: ComputedRef<boolean> = computed(() => {
    return user.value.email.includes('@openvolt');
  });

  //mutations
  function setUserAlert(payload: UserAlert): void {
    userAlert.value.visible = payload.visible;
    userAlert.value.alert_type = payload.alert_type;
    userAlert.value.message = payload.message;
  }

  function setUser(payload: User): void {
    user.value.email = payload.email;
    user.value.id = payload.id;
    user.value.accounts = payload.accounts;
  }

  function setUserAccessToken(payload: string): void {
    user.value.accessToken = payload;
  }

  function setCurrentAccount(payload: Account): void {
    currentAccount.value.name = payload.name;
    currentAccount.value.id = payload.id;
    currentAccount.value.role = payload.role;
    currentAccount.value.theme = payload.theme;
    currentAccount.value.logo = payload.logo;
    currentAccount.value.logo_metadata = payload.logo_metadata;
    currentAccount.value.feature_flags = payload.feature_flags;
  }

  function setCurrentAccountKeys(payload: { testApiKey: string; liveApiKey: string }) {
    currentAccount.value.testApiKey = payload.testApiKey;
    currentAccount.value.liveApiKey = payload.liveApiKey;
  }

  //actions
  async function getUserData(email: string) {
    return await apiClient.getUserData(email, user.value.accessToken).catch(err => {
      toast.error(err.errorMessage);
      return null;
    });
  }

  async function getAccountApiKeys() {
    return await apiClient
      .getAccountApiKeys(currentAccount.value.id, user.value.accessToken)
      .catch(e => {
        toast.error(e.errorMessage);
        return { testApiKey: '', liveApiKey: '' };
      });
  }

  async function handleUserInit(email: string) {
    const userData = await getUserData(email);
    if (userData) {
      setUser(userData);
      const localStorageAccount = window.localStorage.getItem('current_account');
      const account = user.value.accounts.find(a => a.id === localStorageAccount);
      if (account) {
        setCurrentAccount(account);
        setCurrentAccountKeys(await getAccountApiKeys());
      } else {
        setCurrentAccount(user.value.accounts[0]);
        setCurrentAccountKeys(await getAccountApiKeys());
      }
    }
  }

  async function handleAccountChange(payload: Account) {
    window.localStorage.setItem('current_account', payload.id);
    window.location.reload();
  }

  function handleLogout() {
    setUser({ email: '', id: '', accounts: [], accessToken: '' });
    setCurrentAccount({
      id: '',
      name: '',
      apiKey: '',
      testApiKey: '',
      liveApiKey: '',
      theme: '',
      logo: '',
      logo_metadata: {
        name: '',
        file_type: '',
        size: 0,
      },
    });
  }

  async function rotateAccountApiKey() {
    const result = await apiClient.rotateAccountApiKey(
      {
        id: currentAccount.value.id,
        rotateLiveKey: true,
      },
      user.value.accessToken,
    );
    if (result.success) {
      setCurrentAccountKeys({
        liveApiKey: result.data.live_api_key,
        testApiKey: result.data.test_api_key,
      });
      toast.success('Api key changed!');
    }
  }

  async function updateAccountTheme(theme: string) {
    const accountResult = await apiClient.updateAccountTheme(
      currentAccount.value.id,
      theme,
      user.value.accessToken,
    );
    if (accountResult.success) {
      currentAccount.value.theme = theme;
      toast.success('Your theme was successfully updated');
    } else {
      toast.error('We were unable to update your account theme');
    }
  }

  async function updateAccountLogo(logo: string, metadata: ImageMetadata) {
    const accountResult = await apiClient.updateAccountLogo(
      currentAccount.value.id,
      logo,
      metadata,
      user.value.accessToken,
    );
    if (accountResult.success) {
      currentAccount.value.logo = accountResult.data.logo;
      currentAccount.value.logo_metadata = accountResult.data.logo_metadata;
      toast.success('Your logo was successfully updated');
    } else {
      toast.error('We were unable to update your account logo');
    }
  }

  return {
    userAlert,
    user,
    currentAccount,
    accountsList,
    currentApiKey,
    featureFlagList,
    isOpenvoltUser,
    setUserAlert,
    setUser,
    setUserAccessToken,
    setCurrentAccount,
    handleUserInit,
    handleAccountChange,
    handleLogout,
    setCurrentAccountKeys,
    getAccountApiKeys,
    rotateAccountApiKey,
    updateAccountTheme,
    updateAccountLogo,
  };
});
