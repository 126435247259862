<script setup lang="ts">
import { ConsentMethod } from '@/types/hosted-consents/hosted-consent.types';

const props = defineProps<{
  method: ConsentMethod;
}>();

function prettyName() {
  switch (props.method) {
    case ConsentMethod.DIGITAL_CONSENT:
      return 'Digital Consent';
    case ConsentMethod.LETTER_OF_AUTHORITY:
      return 'Letter of Authority';
    case ConsentMethod.THIRD_PARTY_DIGITAL_CONSENT:
      return 'Third Party';
    default:
      return '';
  }
}
</script>

<template>
  <span
    class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-blue-50 text-blue-700 ring-blue-600/20"
    >{{ prettyName() }}</span
  >
</template>

<style scoped></style>
