<template>
  <div class="flex w-full">
    <div class="w-full">
      <ApiPageHeader title="Retrieve a Meter" :endpoint="`/meters/${meter.id}`" method="GET" />
      <div class="mb-20 mt-4 pt-4 border-t border-gray-300" v-if="loadingError">
        {{ loadingError }}
      </div>
      <div v-else>
        <div class="mb-20 mt-4 pt-4 border-t border-gray-300">
          <div class="grid grid-cols-1 md:grid-cols-2 rounded-lg">
            <div>
              <div class="px-4 sm:px-0">
                <h3 class="text-base font-semibold leading-7 text-gray-900">Meter Details</h3>
                <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                  Detailed information about the Meter
                </p>
              </div>
              <div class="mt-6 border-t border-gray-100">
                <dl class="divide-y divide-gray-100">
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Meter Id</dt>
                    <dd class="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {{ meter.id }}
                      <CopyToClipboardButton :toCopy="meter.id" name="Meter ID" />
                    </dd>
                  </div>
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Meter Number</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {{ meter.meterNumber }}
                    </dd>
                  </div>
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Meter Type</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {{ meter.meter_type }}
                    </dd>
                  </div>
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Daily Updates</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <CheckIcon v-if="!meter.one_time_data_pull" class="w-6 text-green-500" />
                      <XMarkIcon v-else class="w-6 text-red-500" />
                    </dd>
                  </div>
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Customer</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {{ meter.customer.name }}
                    </dd>
                  </div>
                  <div
                    v-if="meter.consentId"
                    class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Consent</dt>
                    <dd class="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {{ meter.consentId }}
                      <CopyToClipboardButton :toCopy="meter.consentId" name="Consent Id" />
                    </dd>
                  </div>
                  <div
                    v-if="meter.hostedConsent"
                    class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Hosted Consent</dt>
                    <dd class="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {{ meter.hostedConsent.id }}
                      <CopyToClipboardButton
                        :toCopy="meter.hostedConsent.id"
                        name="Hosted Consent Id" />
                    </dd>
                  </div>
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Address</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {{ meter.address }}
                    </dd>
                  </div>
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Description</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {{ meter.description }}
                    </dd>
                  </div>
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Data source</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <span v-if="meter.dataSource">{{ meter.dataSource }}</span>
                      <span v-else class="text-gray-400 text-sm"
                        >A data source will be added once this meter has a hosted consent</span
                      >
                    </dd>
                  </div>
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Status</dt>
                    <dt>
                      <span
                        class="items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                        :class="{
                          'bg-green-50 text-green-700 ring-green-600/20': meter.status === 'active',
                          'bg-red-50 text-red-700 ring-red-600/20': meter.status === 'inactive',
                          'bg-blue-50 text-blue-700 ring-blue-600/20': meter.status === 'closed',
                        }"
                        >{{ meter.status }}</span
                      >
                    </dt>
                  </div>
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Validity State</dt>
                    <dt>
                      <span
                        v-if="meter.validityState.missingConsent"
                        class="inline-flex items-center rounded-md mx-1 my-1 px-2 py-1 text-xs font-medium ring-1 ring-inset"
                        :class="{
                          'bg-red-50 text-red-700 ring-red-600/20':
                            meter.validityState.missingConsent,
                        }"
                        >Missing Consent</span
                      >
                      <span
                        v-if="meter.validityState.expiredConsent"
                        class="inline-flex items-center rounded-md mx-1 my-1 px-2 py-1 text-xs font-medium ring-1 ring-inset"
                        :class="{
                          'bg-red-50 text-red-700 ring-red-600/20':
                            meter.validityState.expiredConsent,
                        }"
                        >Expired Consent</span
                      >
                      <span
                        v-if="meter.validityState.isDisabledByUser"
                        class="inline-flex items-center rounded-md mx-1 my-1 px-2 py-1 text-xs font-medium ring-1 ring-inset"
                        :class="{
                          'bg-red-50 text-red-700 ring-red-600/20':
                            meter.validityState.isDisabledByUser,
                        }"
                        >Manually Disabled</span
                      >
                      <span
                        v-if="meter.validityState.hasInvalidMeterNumber"
                        class="inline-flex items-center rounded-md mx-1 my-1 px-2 py-1 text-xs font-medium ring-1 ring-inset"
                        :class="{
                          'bg-red-50 text-red-700 ring-red-600/20':
                            meter.validityState.hasInvalidMeterNumber,
                        }"
                        >Invalid Meter Number</span
                      >
                    </dt>
                  </div>
                  <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Last Scanned</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {{ formatDate(meter.lastDataPullDate) }}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <Disclosure v-slot="{ open }">
          <DisclosureButton
            class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
            <div>
              <ApiPageHeader title="Edit Meter" :endpoint="`/meters/${meter.id}`" method="PUT" />
            </div>
            <ChevronDownIcon v-if="!open" class="w-5" />
            <ChevronUpIcon v-if="open" class="w-5" />
          </DisclosureButton>
          <DisclosurePanel class="text-gray-500">
            <form class="mt-4">
              <div
                class="space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                  <div>
                    <div class="flex">
                      <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                        >description</span
                      ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                        >string</span
                      >
                    </div>

                    <div class="text-sm font-sm text-gray-600">The description of the meter</div>
                  </div>

                  <div class="mt-2 sm:col-span-2 sm:mt-0">
                    <div
                      class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                        v-model="description" />
                    </div>
                  </div>
                </div>
                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                  <div>
                    <div class="flex">
                      <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                        >address</span
                      ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                        >string</span
                      >
                    </div>

                    <div class="text-sm font-sm text-gray-600">The address of the meter</div>
                  </div>

                  <div class="mt-2 sm:col-span-2 sm:mt-0">
                    <div
                      class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                        v-model="address" />
                    </div>
                  </div>
                </div>
                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                  <div>
                    <div class="flex">
                      <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                        >hosted_consent_id</span
                      ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                        >string</span
                      >
                    </div>

                    <div class="text-sm font-sm text-gray-600">
                      The hosted consent associated with this meter.
                    </div>
                  </div>

                  <div class="mt-2 sm:col-span-2 sm:mt-0">
                    <div
                      class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                        v-model="hostedConsentId" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex border-0 justify-end">
                <button
                  type="button"
                  class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  @click="updateMeter">
                  Submit
                </button>
              </div>
            </form>
          </DisclosurePanel>
        </Disclosure>

        <div
          class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
          <div>
            <ApiPageHeader
              title="Enable Meter"
              :endpoint="`/meters/${meter.id}/enable`"
              method="PUT" />
          </div>
          <div>
            <button
              type="button"
              class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              @click="enableMeter">
              Enable
            </button>
          </div>
        </div>

        <div
          class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
          <div>
            <ApiPageHeader
              title="Disable Meter"
              :endpoint="`/meters/${meter.id}/disable`"
              method="PUT" />
          </div>
          <div>
            <button
              type="button"
              class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              @click="disableMeter">
              Disable
            </button>
          </div>
        </div>

        <div
          class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
          <div>
            <ApiPageHeader title="Delete Meter" :endpoint="`/meters/${meter.id}`" method="DELETE" />
          </div>
          <div>
            <button
              class="w-32 h-9 ml-3 text-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none disabled:opacity-50"
              @click="openDeleteConfirmation">
              Delete Meter
            </button>
            <ConfirmAction
              v-if="isDeleteConfirmModalOpen"
              title="Delete Meter"
              message="Are you sure you want to Delete this Meter? This action cannot be undone!"
              confirmButtonText="Delete Meter"
              @confirm="deleteMeter"
              @close="closeDeleteConfirmation" />
          </div>
        </div>

        <div>
          <div
            class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
            <div>
              <ApiPageHeader
                title="
                              Retrieve Interval Data"
                :endpoint="`/interval-data`"
                method="GET" />
            </div>
          </div>
          <div class="mt-4 mb-10 w-full">
            <div
              class="space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <div>
                  <div class="flex">
                    <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >lookback</span
                    ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >string</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">
                    Timeperiod to lookback, in the form of a number followed by a letter, e.g. 1d,
                    1w, 3m, 1y
                  </div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="lookback" />
                  </div>
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <div>
                  <div class="flex">
                    <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >granularity</span
                    ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >string</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">
                    <div>How granular to return the data. Half-hourly is denoted by 'hh'</div>
                  </div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div class="flex">
                    <div>
                      <select
                        v-model="granularity"
                        class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                        <option>qh</option>
                        <option>hh</option>
                        <option>day</option>
                        <option>week</option>
                        <option>month</option>
                        <option>year</option>
                      </select>
                      <div class="text-xs text-gray-500 flex gap-2 flex-col mt-2">
                        <span>
                          For chart rendering purposes, there are limits to the maximum number of
                          days you can select for granularity types.
                          <strong>This does not affect API calls.</strong>
                        </span>
                        <ul>
                          <li>qh: 7 days</li>
                          <li>hh: 14 days</li>
                          <li>day: 100 days</li>
                          <li>week: 1000 days</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex border-0 justify-end">
              <button
                :disabled="disabledGranularities.indexOf(granularity) > -1"
                @click="onSubmit"
                type="button"
                class="disabled:bg-blue-200 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 disabled:text-gray-500">
                Show Data
              </button>
              <button
                type="button"
                class="items-center px-3 py-2 border border-transparent text-sm leading-4 ml-3 font-medium rounded-md text-black-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                @click="onDownloadCSV">
                Download as CSV
              </button>
            </div>
          </div>

          <div class="w-full 2xl:w-2/3 mt-8 border border-gray-300 p-4 bg-slate-50">
            <Heading>Consumption</Heading>
            <apexchart :options="chartOptions" :series="chartData.series"></apexchart>
          </div>
          <div
            v-if="
              carbonIntensityData.series.length > 0 &&
              meter.meter_type === MeterEnergyType.ELECTRICITY
            "
            class="w-full 2xl:w-2/3 mt-8 border border-gray-300 p-4 bg-slate-50">
            <Heading>Carbon Emissions</Heading>
            <apexchart
              :options="carbonIntensityOptions"
              :series="carbonIntensityData.series"></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Meter } from '@/types/meter';
import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';
import type { CustomerModel } from '@/types/customer/customer.types';
import { ref, onMounted, watch, computed } from 'vue';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import moment from 'moment';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import ConfirmAction from '@/components/ConfirmAction/index.vue';
import CopyToClipboardButton from '@/components/CopyToClipboardButton/index.vue';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { MeterEnergyType } from '@/types/meter/meter.types';
import Heading from '@/components/Heading/Heading.vue';
import { type Lookback, lookbackToDays } from '@/helpers/timePeriod';

const emptyMeter: Meter = {
  id: '',
  meterNumber: '',
  dataSource: '',
  customer: {} as CustomerModel,
  address: '',
  description: '',
  status: '',
  hostedConsent: {} as HostedConsentModel,
  consentId: '',
  lastDataPullDate: '',
  lastDataPullStatus: '',
  latestDateEntry: '',
  enabled: false,
  valid: false,
  errorCode: null,
  validityState: {
    missingConsent: false,
    expiredConsent: false,
    isDisabledByUser: false,
    hasInvalidMeterNumber: false,
  },
  created_at: '',
  meter_type: '',
  one_time_data_pull: false,
};

const router = useRouter();
const route = useRoute();
const apiClient = new ApiClient();
const userStore = useUserStore();

const meter = ref<Meter>(emptyMeter);
const granularity = ref<string>('hh');
const lookback = ref<Lookback>('2w');
const startDate = ref<string>('');
const endDate = ref<string>('');
const isDeleteConfirmModalOpen = ref<boolean>(false);

const toast = useToast();
const address = ref<string>('');
const description = ref<string>('');
const hostedConsentId = ref<string>('');
const loadingError = ref<string | null>(null);

const lookbackDays = ref<number>(14);
const disabledGranularities = ref<string[]>([]);

function calculateDisabledGranularities() {
  try {
    const days = lookbackToDays(lookback.value);
    const dgs = [];
    if (days > 7) {
      dgs.push('qh');
    }
    if (days > 14) {
      dgs.push('hh');
    }
    if (days > 100) {
      dgs.push('day');
    }
    if (days > 1000) {
      dgs.push('week');
    }
    lookbackDays.value = days;
    disabledGranularities.value = dgs;
  } catch (e) {
    return;
  }
}

watch(lookback, () => {
  calculateDisabledGranularities();
});

const chartUnit = ref('');

const chartOptions = computed(() => {
  return {
    theme: {
      palette: 'palette2',
    },
    chart: {
      id: 'chart',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
      type: 'bar',
    },
    stroke: {
      show: true,
      curve: ['smooth'],
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: true,
      },
    },
    yaxis: [
      {
        seriesName: 'electricity',
        show: true,
        title: {
          text: chartUnit.value,
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: function (value: any) {
            return value.toFixed(2);
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: 'dd MMM yyyy - H:mm',
      },
      y: {
        formatter: function (value: any) {
          return `${value.toFixed(2)} ${chartUnit.value}`;
        },
      },
    },
  };
});

const chartData = ref({
  series: [
    {
      name: 'Consumption',
      data: [] as any[],
    },
  ],
});

const carbonIntensityOptions = computed(() => {
  return {
    theme: {
      palette: 'palette7',
    },
    chart: {
      id: 'chart',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
      type: 'bar',
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: true,
      },
    },
    yaxis: [
      {
        seriesName: 'intensity',
        show: true,
        title: {
          text: 'gCO2',
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: function (value: any) {
            return value.toFixed(2);
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: 'dd MMM yyyy - H:mm',
      },
      y: {
        formatter: function (value: any) {
          return `${value.toFixed(2)} gCO2`;
        },
      },
    },
  };
});
const carbonIntensityData = ref({
  series: [
    {
      name: 'Carbon Emissions',
      data: [] as any[],
    },
  ],
});
function openDeleteConfirmation() {
  isDeleteConfirmModalOpen.value = true;
}

function closeDeleteConfirmation() {
  isDeleteConfirmModalOpen.value = false;
}

async function deleteMeter() {
  await apiClient
    .deleteMeter({
      id: route.params.id as string,
      apiKey: userStore.currentApiKey,
    })
    .then(() => {
      toast.success('Meter deleted');
      router.push('/meters');
    })
    .catch(e => {
      toast.error(e.errorMessage);
    });
}

async function updateMeter() {
  await apiClient
    .updateMeter({
      id: route.params.id as string,
      apiKey: userStore.currentApiKey,
      address: address.value,
      description: description.value,
      hosted_consent_id: hostedConsentId.value,
    })
    .then(async () => {
      toast.success('Meter updated');
      meter.value = await getMeterDetails();
      description.value = '';
      address.value = '';
    })
    .catch(e => {
      toast.error(e.errorMessage);
    });
}

function formatDate(value: string) {
  if (value) {
    return moment(String(value)).format('DD/MM/YY');
  }
}

async function getMeterDetails() {
  return await apiClient
    .getMeter({
      meterId: route.params.id as string,
      apiKey: userStore.currentApiKey,
    })
    .catch(e => {
      loadingError.value = e.errorMessage;
      return emptyMeter;
    });
}

function buildIntervalQuery() {
  return {
    meterId: route.params.id as string,
    lookback: lookback.value,
    granularity: granularity.value,
    apiKey: userStore.currentApiKey,
    startDate: startDate.value,
    endDate: endDate.value,
    includeCarbonEmissions: true,
  };
}
async function getJSONIntervalData() {
  return apiClient.getIntervalData(buildIntervalQuery(), false);
}

async function getCSVIntervalData() {
  return apiClient.getIntervalData(buildIntervalQuery(), true);
}

async function enableMeter() {
  await apiClient
    .enableMeter({
      id: route.params.id as string,
      apiKey: userStore.currentApiKey,
    })
    .then(async () => {
      meter.value = await getMeterDetails();
      toast.success('Meter enabled');
    })
    .catch(e => {
      toast.error(e.errorMessage);
    });
}

async function disableMeter() {
  await apiClient
    .disableMeter({
      id: route.params.id as string,
      apiKey: userStore.currentApiKey,
    })
    .then(async () => {
      meter.value = await getMeterDetails();
      toast.success('Meter disabled');
    })
    .catch(e => {
      toast.error(e.errorMessage);
    });
}

async function onSubmit() {
  const intervalData = await getJSONIntervalData();
  chartData.value.series[0].data = intervalData.consumption;
  carbonIntensityData.value.series[0].data = intervalData.intensity;
}

async function onDownloadCSV() {
  getCSVIntervalData().then((intervalData: string) => {
    if (intervalData.trim().length === 0) {
      toast.error('No data to download');
      return;
    }
    toast.success('Downloading data');
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(intervalData);
    anchor.target = '_blank';
    anchor.download = `interval-data-${lookback.value}-${granularity.value}-${route.params.id}.csv`;
    anchor.click();
  });
}

onMounted(async () => {
  meter.value = await getMeterDetails();
  const intervalData = await getJSONIntervalData();
  calculateDisabledGranularities();
  if (!intervalData) return;
  const first = intervalData.consumption[0];
  if (first) {
    switch (first.type) {
      case MeterEnergyType.ELECTRICITY:
        chartUnit.value = 'kWh';
        break;
      case MeterEnergyType.GAS:
        chartUnit.value = 'm³';
        break;
      default:
        chartUnit.value = 'kWh';
    }
  }
  chartData.value.series[0].data = intervalData.consumption;
  carbonIntensityData.value.series[0].data = intervalData.intensity;
});

watch(
  () => userStore.currentApiKey,
  async () => {
    router.replace('/meters');
  },
);
</script>
