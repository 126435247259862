<script setup lang="ts">
import { useHomepageStore } from '@/stores/homepageStore';
import { onMounted, ref, watch } from 'vue';
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline';
import type { TriggerEventResponse } from '@/types/events/event.types';
import Heading from '@/components/Heading/Heading.vue';
import Loading from '@/components/Loading/Loading.vue';

const homepageStore = useHomepageStore();

const loading = ref(true);
const event = ref<TriggerEventResponse | null>(null);

async function fetchEvent() {
  if (loading.value && homepageStore.currentSelectedEvent?.id) {
    event.value = await homepageStore.getEvent(homepageStore.currentSelectedEvent.id);
    loading.value = false;
  }
}

onMounted(async () => {
  await fetchEvent();
});

watch(
  () => homepageStore.currentSelectedEvent,
  async () => {
    if (
      homepageStore.currentSelectedEvent !== null &&
      homepageStore.currentSelectedEvent?.id !== event.value?.id
    )
      loading.value = true;
    await fetchEvent();
  },
);
</script>

<template>
  <div
    v-if="!event"
    class="h-full flex justify-center items-center text-gray-500 text-lg border border-gray-300">
    Select an event from the left to view it in detail
  </div>
  <div v-if="event && loading" class="flex items-center justify-center h-full"><Loading /></div>
  <div v-if="event && !loading">
    <div class="p-4 border-b border-gray-300 flex justify-between items-start">
      <div>
        <Heading>{{ event.event_id }}</Heading>
        <span class="text-gray-500">{{
          new Date(event.created_at).toLocaleString('default', {
            timeStyle: 'short',
            dateStyle: 'full',
          })
        }}</span>
      </div>
      <RouterLink
        :to="{
          path: `/account-event/${event.id}`,
        }">
        <ArrowTopRightOnSquareIcon class="w-6 text-gray-500" />
      </RouterLink>
    </div>
    <div class="p-4">
      <span class="text-gray-500">{{ event.description }}</span>
    </div>
    <div class="mx-4">
      <Heading>Payload</Heading>
      <div class="mt-2 border border-gray-300 shadow rounded max-h-[420px] overflow-y-auto">
        <highlightjs
          class="highlight"
          language="json"
          :code="JSON.stringify(event.payload, null, 2)" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
