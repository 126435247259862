import { createAuth0 } from '@auth0/auth0-vue';

export const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH_DOMAIN || '',
  clientId: import.meta.env.VITE_CLIENT_ID || '',
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: import.meta.env.VITE_JWT_AUDIENCE || '',
  },
});
