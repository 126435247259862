<script setup lang="ts">
import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';
import { ConsentStatus, status } from '@/views/HostedConsents/status';

const props = defineProps<{
  hostedConsent: HostedConsentModel;
}>();
</script>

<template>
  <span
    v-if="status(props.hostedConsent) === ConsentStatus.VALID"
    class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-green-50 text-green-700 ring-green-600/20"
    >Valid</span
  >
  <span
    v-if="status(props.hostedConsent) === ConsentStatus.PENDING"
    class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-yellow-50 text-yellow-700 ring-yellow-600/20"
    >Pending</span
  >
  <span
    v-if="status(props.hostedConsent) === ConsentStatus.INVALID"
    class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-orange-50 text-orange-700 ring-orange-600/20"
    >Invalid</span
  >
  <span
    v-if="status(props.hostedConsent) === ConsentStatus.EXPIRED"
    class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-red-50 text-red-700 ring-red-600/20"
    >Expired</span
  >
  <span
    v-if="status(props.hostedConsent) === ConsentStatus.REVOKED"
    class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-orange-50 text-orange-700 ring-orange-600/20"
    >Revoked</span
  >
</template>

<style scoped></style>
