interface Params {
  [key: string]: string | number | null | boolean;
}

const removeEmptyProperties = (params: Params) => {
  return Object.entries(params)
    .filter(([, v]) => {
      return v != null && v !== undefined && v !== '';
    })
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

export default removeEmptyProperties;
