<script setup lang="ts">
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import { useHostedConsentStore } from '@/stores/hostedConsentStore';
import { onMounted, ref, watch } from 'vue';
import HostedConsentsTable from '@/components/HostedConsentsTable/HostedConsentsTable.vue';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';
import Fuse from 'fuse.js';
import Toggle from '@/components/Toggle/Toggle.vue';
import { ConsentStatus, status } from '@/views/HostedConsents/status';

const apiClient = new ApiClient();
const hostedConsentStore = useHostedConsentStore();
const userStore = useUserStore();

const showValid = ref(true);
const showExpired = ref(false);
const showRevoked = ref(false);
const showPending = ref(false);

const searchQuery = ref('');
const refinedConsents = ref<HostedConsentModel[]>([]);

onMounted(async () => {
  const data = await apiClient.getHostedConsents(userStore.currentApiKey).catch(() => null);
  if (data) {
    hostedConsentStore.hostedConsents = data;
  }
});

watch(
  () => userStore.currentApiKey,
  async () => {
    const data = await apiClient.getHostedConsents(userStore.currentApiKey).catch(() => null);
    if (data) {
      hostedConsentStore.hostedConsents = data;
    }
  },
);

watch(
  () => hostedConsentStore.hostedConsents,
  async () => {
    refinedConsents.value = hostedConsentStore.hostedConsents;
    performFiltering(searchQuery.value);
  },
);

watch([searchQuery, showValid, showPending, showExpired, showRevoked], () => {
  performFiltering(searchQuery.value);
});

function performFiltering(searchQuery: string) {
  let consents = hostedConsentStore.hostedConsents;
  if (searchQuery.trim().length > 0) {
    const options = {
      includeScore: false,
      keys: [
        'customer.name',
        'customer.address',
        'customer.email',
        { name: 'flow_type', weight: 2 },
        'description',
      ],
      threshold: 0.3,
    };

    const fuse = new Fuse(hostedConsentStore.hostedConsents, options);

    consents = fuse.search(searchQuery).map(fuseResult => fuseResult.item);
  }

  if (!showRevoked.value) {
    consents = consents.filter(c => {
      return status(c) !== ConsentStatus.REVOKED;
    });
  }

  if (!showExpired.value) {
    consents = consents.filter(c => {
      return status(c) !== ConsentStatus.EXPIRED && status(c) !== ConsentStatus.INVALID;
    });
  }

  if (!showValid.value) {
    consents = consents.filter(c => {
      return status(c) !== ConsentStatus.VALID;
    });
  }

  if (!showPending.value) {
    consents = consents.filter(c => {
      return status(c) !== ConsentStatus.PENDING;
    });
  }

  refinedConsents.value = consents;
}

function toggleExpired() {
  showExpired.value = !showExpired.value;
}

function toggleRevoked() {
  showRevoked.value = !showRevoked.value;
}

function toggleValid() {
  showValid.value = !showValid.value;
}

function togglePending() {
  showPending.value = !showPending.value;
}
</script>

<template>
  <ApiPageHeader
    title="Hosted Consents"
    endpoint="/hosted-consents"
    method="get"
    button-link="/hosted-consents/new"
    button-link-text="Create hosted consent" />
  <div class="border-gray-300 border-b pb-2 mb-2 mt-4">
    <div class="flex justify-between items-start">
      <div class="gap-4 flex-col flex items-start">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Enter a search query"
          class="rounded-md border-gray-500" />
        <div class="flex gap-4">
          <Toggle label="Include Valid" :on="showValid" @click="toggleValid" />
          <Toggle label="Include Pending" :on="showPending" @click="togglePending" />
          <Toggle label="Include Expired/Invalid" :on="showExpired" @click="toggleExpired" />
          <Toggle label="Include Revoked" :on="showRevoked" @click="toggleRevoked" />
        </div>
      </div>
    </div>
  </div>
  <HostedConsentsTable :hosted-consents="refinedConsents" />
</template>

<style scoped></style>
