import { ApiClient } from '@/helpers/apiClient';
import { defineStore } from 'pinia';
import type { NotificationAccountResponse } from '@/types/account/notification-account.types';
import { ref } from 'vue';
import { useUserStore } from '@/stores/user';
import { updateRef } from '@/helpers/refHelper';
import type { TriggerEventResponse } from '@/types/events/event.types';
import type { PageQuery, PageResponse } from '@/types/page/page.types';

const api = new ApiClient();

export const useNotificationAccountStore = defineStore('NotificationAccountStore', () => {
  const userStore = useUserStore();
  const notificationAccount = ref<NotificationAccountResponse | null>(null);
  const accountEvents = ref<PageResponse<TriggerEventResponse> | null>(null);
  const accountEvent = ref<TriggerEventResponse | null>(null);

  async function getNotificationAccount() {
    return await updateRef(
      api.getNotificationAccount(userStore.currentApiKey),
      notificationAccount,
      false,
    );
  }

  async function createNotificationAccount() {
    return await updateRef(
      api.createNotificationAccount(userStore.currentApiKey, { email: userStore.user.email }),
      notificationAccount,
    );
  }

  async function listAccountEvents(pageQuery?: PageQuery) {
    return await updateRef(
      api.listAccountEvents(userStore.currentApiKey, pageQuery),
      accountEvents,
    );
  }

  async function getAccountEvent(eventId: string) {
    return await updateRef(api.getAccountEvent(userStore.currentApiKey, eventId), accountEvent);
  }

  return {
    notificationAccount,
    accountEvents,
    accountEvent,
    getNotificationAccount,
    createNotificationAccount,
    listAccountEvents,
    getAccountEvent,
  };
});
