import type { Ref } from 'vue';
import { useToast } from 'vue-toastification';

const toast = useToast();

export async function updateRef<T>(
  promise: Promise<T | void>,
  ref: Ref<T | null>,
  showToast: boolean = true,
): Promise<T | null> {
  try {
    const response = await promise;
    ref.value = response || null;
    return ref.value;
  } catch (error: any) {
    if (showToast) {
      const message = error.response?.data?.message || error.message;
      toast.error(message);
    }
    ref.value = null;
    return null;
  }
}
