<template>
  <div class="w-full">
    <ApiPageHeader title="Create a new Inverter" endpoint="/inverters" method="POST" />
    <form class="my-4 pt-4 border-t border-gray-300">
      <div
        class="space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >inverter_number</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The inverter number</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="inverterNumber" />
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >inverter_serial</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The inverter_serial</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="inverterSerial" />
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >key_id</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The key id</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="keyId" />
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >key_secret</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The key secret</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="keySecret" />
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >customer_id</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The id of the customer</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="customerId" />
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >consent_id</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The id of the consent</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="consentId" />
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >hosted_consent_id</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The id of the hosted consent</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="hostedConsentId" />
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >description</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The description of the inverter</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="description" />
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >address</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The address of the inverter</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div
              class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="text"
                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                v-model="address" />
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >data_source</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The source of the data</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div class="flex">
              <div>
                <select
                  v-model="dataSource"
                  class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                  <option value="enphase">Enphase</option>
                  <option value="fronius">Fronius</option>
                  <option value="growatt">Growatt</option>
                  <option value="huawei">Huawei</option>
                  <option value="solar-edge">Solar Edge</option>
                  <option value="solis">Solis</option>
                  <option value="sma">SMA</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <div>
            <div class="flex">
              <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >update_frequency</span
              ><span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">string</span>
            </div>

            <div class="text-sm font-sm text-gray-600">The frequency of the updates</div>
          </div>

          <div class="mt-2 sm:col-span-2 sm:mt-0">
            <div class="flex">
              <div>
                <select
                  v-model="updateFrequency"
                  class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                  <option>hourly</option>
                  <option>daily</option>
                  <option>monthly</option>
                  <option>yearly</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex border-0 justify-end">
        <button
          type="button"
          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          @click="onSubmit">
          Submit
        </button>
        <ConfirmAction
          v-if="isConfirmModalOpen"
          title="Create Inverter"
          message="Warning! If you create an inverter without a consent_id, it's status will be 'inactive' until one is provided"
          confirmButtonText="Create Inverter"
          @confirm="createInverter"
          @close="closeCreateConfirmation" />
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ConfirmAction from '@/components/ConfirmAction/index.vue';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';

const inverterNumber = ref<string>('');
const inverterSerial = ref<string>('');
const keyId = ref<string>('');
const keySecret = ref<string>('');
const address = ref<string>('');
const customerId = ref<string>('');
const consentId = ref<string>('');
const hostedConsentId = ref<string>('');
const description = ref<string>('');
const dataSource = ref<string>('');
const updateFrequency = ref<string>('');
const isConfirmModalOpen = ref<boolean>(false);
const toast = useToast();

const apiClient = new ApiClient();
const userStore = useUserStore();
const router = useRouter();

async function onSubmit() {
  if (consentId.value === '') {
    openCreateConfirmation();
  } else {
    createInverter();
  }
}

function openCreateConfirmation() {
  isConfirmModalOpen.value = true;
}

function closeCreateConfirmation() {
  isConfirmModalOpen.value = false;
}

async function createInverter() {
  await apiClient
    .createNewInverter({
      inverter_number: inverterNumber.value,
      inverter_serial: inverterSerial.value,
      key_id: keyId.value,
      key_secret: keySecret.value,
      address: address.value,
      customer_id: customerId.value,
      consent_id: consentId.value,
      hosted_consent_id: hostedConsentId.value,
      description: description.value,
      data_source: dataSource.value,
      update_frequency: updateFrequency.value,
      apiKey: userStore.currentApiKey,
    })
    .then(() => {
      toast.success('Inverter created');
      router.push('/inverters');
    })
    .catch(e => {
      toast.error(e.errorMessage);
    });
}
</script>
