import zod from 'zod';
import { NLDataSource, type NLDataSourceInfo, NLDataSourceMapping } from './MeterDataSource.nl';
import { UKDataSource, type UKDataSourceInfo, UKDataSourceMapping } from './MeterDataSource.uk';

export const MeterDataSource = { ...NLDataSource, ...UKDataSource };
export type MeterDataSource = NLDataSource | UKDataSource;

export type MeterDataSourceInfo = NLDataSourceInfo | UKDataSourceInfo;

export const DataSourceMapping: Record<MeterDataSource, zod.Schema> = {
  ...NLDataSourceMapping,
  ...UKDataSourceMapping,
};
