<template>
  <table class="divide-y w-full divide-gray-300">
    <thead>
      <tr>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          Attempt Id
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          Dispatch Id
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          Delivery Status
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          Http Status Code
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          Attempt Number
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          Attempt Date
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          Scheduled Date
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <tr>
        <td colspan="8" class="text-center py-8 text-gray-500">Coming Soon!</td>
      </tr>
    </tbody>
  </table>
</template>
