import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ApiClient } from '@/helpers/apiClient';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/stores/user';
import router from '@/router';
import {
  ConsentFlowType,
  ConsentMethod,
  type HostedConsentModel,
} from '@/types/hosted-consents/hosted-consent.types';

export type CreateConsentPayload = {
  customer_id?: string;
  description?: string;
  expiry?: string;
  redirect_url?: string;
  flow_type?: ConsentFlowType | null;
  country_specific_configuration?: Record<string, unknown>;
  meter_numbers?: string[];
  consentee_email?: string;
  consentee_full_name?: string;
  consentee_job_title?: string;
  consent_method?: ConsentMethod;
};

const apiClient = new ApiClient();
const toast = useToast();

export const useHostedConsentStore = defineStore('HostedConsentStore', () => {
  const userStore = useUserStore();
  const hostedConsents = ref<HostedConsentModel[]>([]);
  const hostedConsent = ref<HostedConsentModel | null>(null);
  const hostedConsentEvidence = ref<string | null>(null);

  async function getHostedConsent(id: string) {
    try {
      const { data } = await apiClient.getHostedConsent(userStore.currentApiKey, id);

      hostedConsent.value = data;
      if (data.evidence_document_metadata) {
        const evidence = await apiClient.getHostedConsentEvidence(userStore.currentApiKey, id);
        hostedConsentEvidence.value = evidence.data.data;
      }
    } catch (error: any) {
      toast.error(error.errorMessage);
    }
  }

  async function createHostedConsent(payload: CreateConsentPayload) {
    try {
      const res = await apiClient.createHostedConsent(userStore.currentApiKey, payload);
      const consent = res.data.data;
      toast.success('Hosted consent successfully created');
      router.push(`/hosted-consents/${consent.id}`);
    } catch (error: any) {
      toast.error(error.errorMessage);
    }
  }

  return {
    hostedConsent,
    hostedConsents,
    hostedConsentEvidence,
    createHostedConsent,
    getHostedConsent,
  };
});
