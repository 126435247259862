import type { FileMetadata } from '@/types/common.types';
import type { AccountModel } from '@/types/account/account.types';
import type { CustomerModel } from '@/types/customer/customer.types';

export enum ConsentFlowType {
  UK_COMMERCIAL_BLANKET = 'gb.commercial.blanket',
  // UK_COMMERCIAL_SPECIFIC = 'gb.commercial.specific', @todo leaving this here for now but not available yet
  UK_RESIDENTIAL_SPECIFIC = 'gb.residential.specific',
  NL_COMMERCIAL_SPECIFIC = 'nl.commercial.specific',
  NL_RESIDENTIAL_SPECIFIC = 'nl.residential.specific',
  ES_COMMERCIAL_SPECIFIC = 'es.commercial.specific',
  ES_RESIDENTIAL_SPECIFIC = 'es.residential.specific',
  FR_COMMERCIAL_SPECIFIC = 'fr.commercial.specific',
  FR_RESIDENTIAL_SPECIFIC = 'fr.residential.specific',
  // FI_SPECIFIC = 'fi.specific',
}

export enum ConsentMethod {
  LETTER_OF_AUTHORITY = 'letter-of-authority',
  DIGITAL_CONSENT = 'digital-consent',
  THIRD_PARTY_DIGITAL_CONSENT = 'third-party-digital-consent',
}

export interface HostedConsentModel {
  id: string;
  object: string;
  account: AccountModel;
  customer: CustomerModel;
  description: string;
  link_expiration_date: string;
  expiry: string;
  redirect_url: string;
  flow_type: ConsentFlowType;
  contextual_data?: Record<string, never>;
  consent_method?: ConsentMethod;
  consentee_email?: string;
  consentee_email_note?: string;
  consentee_email_code?: string;
  consentee_email_verified: boolean;
  consentee_job_title?: string;
  consentee_full_name?: string;
  evidence_document?: string;
  evidence_document_metadata?: FileMetadata;
  meter_numbers?: string[];
  meter_country?: string[];
  token: string;
  revoke_token: string;
  renewal_token: string;
  hosted_consent_version?: string;
  created_at: string;
  updated_at?: string;
  revoked_at?: string;
  consented_at?: string;
  deleted_at?: string;
  hosted_consent_link: string;
  evidence: string | null;
  valid: boolean;
}
